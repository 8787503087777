import { Injectable } from "@angular/core";
import { Group } from "../models/group.model";
import { UtilityService } from "./utility.service";

@Injectable({
  providedIn: "root"
})
export class NotifyGroupsService {
  constructor() { }

  /*
   * Currently in PROD there are following Pendel groups P-LF, P-TV, P-SV, P-KSV
   * The new requirement is to show an aggregate group P-SV/KSV/OTS in the web FE which
   * maps to groups P-SV/OTS, P-KSV in the backend.
   * Therefore the groups when saved will be mapped from P-SV/KSV/OTS to P-SV/OTS, P-KSV
   * Similarly when displaying in FE the groups from BE P-SV/OTS, P-KSV (along with legacy
   * group P-SV) will map to P-SV/KSV/OTS
   */
  static pendelAggregateGroup = "P-SV/OTS/KSV";
  static mapFromGroups = ["P-SV", "P-SV/OTS", "P-KSV"];
  static mapToGroups = ["P-SV/OTS", "P-KSV"];

  static notifyGroupsList = [
    new Group("LF", "LF", false),
    new Group("SV", "SV", false)
  ];

  public static getnotifyGroupsList() {
    return NotifyGroupsService.notifyGroupsList;
  }

  public static mapIndividualGroupsToAggregateGroup(notifyGroups: string[]):boolean {
    if (notifyGroups.some(el => NotifyGroupsService.mapFromGroups.includes(el))) {
      NotifyGroupsService.mapFromGroups.forEach(el => UtilityService.removeElementFromArray(notifyGroups, el));
      notifyGroups.push(NotifyGroupsService.pendelAggregateGroup);
      return true;
    }
    return false;
  }

  public static mapAggregateGroupToIndividualGroups(notifyGroups: string[]) {
    if (notifyGroups.includes(NotifyGroupsService.pendelAggregateGroup)) {
      UtilityService.removeElementFromArray(notifyGroups, NotifyGroupsService.pendelAggregateGroup);
      NotifyGroupsService.mapToGroups.forEach(el => notifyGroups.push(el));
    }
  }
}
