<ul>
  <ng-container *ngFor="let menuItem of menuItems">
    <li *ngIf="shouldShow(menuItem)" [routerLinkActive]="['active']">
      <a
        [routerLink]="menuItem.path"
        class="menu-link"
        (click)="onClick(menuItem)"
      >
        {{ menuItem.displayName }}
      </a>
    </li>
  </ng-container>
</ul>
