import {definePreset} from '@primeng/themes';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import localeSv from "@angular/common/locales/sv";
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MenuComponent} from "./core/menu/menu.component";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {MsalModule} from "@azure/msal-angular";
import {BrowserCacheLocation, InteractionType, PublicClientApplication} from "@azure/msal-browser";
import {environment} from "../environments/environment";
import {AuthInterceptor} from "./interceptors/AuthInterceptor";
import {MessageListComponent} from "./pages/traffic-information/message-list/message-list.component";
import {FilterComponent} from "./pages/traffic-information/message-list/filter/filter.component";
import {MatIconModule} from "@angular/material/icon";
import {DatePickerModule} from "primeng/datepicker";
import {PaginatorModule} from "primeng/paginator";
import {MatButtonModule} from "@angular/material/button";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {UserInfoComponent} from "./pages/user-info/user-info.component";
import {UserInfoFilterPipe} from "./shared/userinfofilter.pipe";
import {ProgressSpinnerComponent} from "./shared/progress-spinner/progress-spinner.component";
import {ErrorBoxComponent} from "./shared/error-box/error-box.component";
import {HeaderComponent} from "./pages/traffic-information/message/header/header.component";
import {
    AppMessageFormComponent
} from "./pages/traffic-information/message/message-form/app-message-form/app-message-form.component";
import {MessageFormComponent} from "./pages/traffic-information/message/message-form/message-form.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MessageComponent} from "./pages/traffic-information/message/message.component";
import {
    WebMessageFormComponent
} from "./pages/traffic-information/message/message-form/web-message-form/web-message-form.component";
import {RestrictedComponent} from "./pages/restricted/restricted.component";
import {TrafficInformationComponent} from "./pages/traffic-information/traffic-information.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {registerLocaleData} from "@angular/common";
import {ContactsComponent} from "./pages/phonebook/contacts/contacts.component";
import {ContactsTableComponent} from "./pages/phonebook/contacts/contacts-table/contacts-table.component";
import {StoreModule} from '@ngrx/store';
import {categoriesFeatureKey} from "./store/categories/categories.selector";
import {categoriesReducer} from "./store/categories/categories.reducer";
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {ContactsEffects} from "./store/contacts/contacts.effects";
import {MatExpansionModule} from "@angular/material/expansion";
import {CategoriesComponent} from "./pages/phonebook/categories/categories.component";
import {CdkDrag, CdkDropList} from "@angular/cdk/drag-drop";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatInputModule} from "@angular/material/input";
import {MatTableModule} from "@angular/material/table";
import {FilterSearchBoxComponent} from "./pages/phonebook/shared/filter-search-box/filter-search-box.component";
import {MatListModule} from "@angular/material/list";
import {CategoriesEffects} from "./store/categories/categories.effects";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {NavigationHeaderComponent} from "./pages/phonebook/shared/navigation-header/navigation-header.component";
import { TruncatePipe } from './truncate.pipe';
import { providePrimeNG } from "primeng/config";
import Nora from '@primeng/themes/nora';

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

// export function loggerCallback(logLevel, message, piiEnabled) {
//   console.log("client logging\n" + message);
// }

registerLocaleData(localeSv, "sv");

export const protectedResourceMap: Map<string,string[]> = new Map([
  [environment.messagesBaseUrl, [environment.messagesScopes]],
  [environment.contactApiBaseUrl, [environment.contactScopes]],
  [environment.graphUrl, ["user.read"]]
]);

@NgModule({
  declarations: [
    AppComponent,
    MessageListComponent,
    FilterComponent,
    UserInfoComponent,
    UserInfoFilterPipe,
    ProgressSpinnerComponent,
    ErrorBoxComponent,
    HeaderComponent,
    AppMessageFormComponent,
    MessageFormComponent,
    MessageComponent,
    WebMessageFormComponent,
    RestrictedComponent,
    TrafficInformationComponent,
    ContactsComponent,
    ContactsTableComponent,
    TruncatePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MenuComponent,
    MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
        auth: {
          clientId: environment.clientID,
          authority: environment.loginUrl + environment.tenantID,
          redirectUri: environment.loginRedirect,
          postLogoutRedirectUri: environment.url,
          navigateToLoginRequestUrl: true
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: isIE // set to true for IE 11
        },
        system: {
          // loggerOptions: {
          //   loggerCallback: loggerCallback,
          //   piiLoggingEnabled: true
          // }
        }
      }),
      {
        interactionType: InteractionType.Redirect //MSAL Guard Configuration
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: protectedResourceMap // MSAL Interceptor Configuration
      }),
    MatIconModule,
    DatePickerModule,
    PaginatorModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTooltipModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature(categoriesFeatureKey, categoriesReducer),
    StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production,
        connectInZone: true
    }),
    EffectsModule.forRoot(ContactsEffects, CategoriesEffects),
    MatExpansionModule,
    CdkDrag,
    CdkDropList,
    MatCheckboxModule,
    MatInputModule,
    MatTableModule,
    MatListModule,
    MatSnackBarModule,
    FilterSearchBoxComponent,
    CategoriesComponent,
    NavigationHeaderComponent,
    FormsModule,
  ],
  providers: [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor, multi: true
    },
    {
        provide: LOCALE_ID,
        useValue: "sv-SE"
    },
    providePrimeNG({
      theme: {
          preset: definePreset(Nora, {
              semantic: {
                  primary: {
                      50: '#1A93D1',
                      100: '#1A93D1',
                      200: '#1A93D1',
                      300: '#1A93D1',
                      400: '#1A93D1',
                      500: '#1A93D1',
                      600: '#1A93D1',
                      700: '#1A93D1',
                      800: '#1A93D1',
                      900: '#1A93D1',
                      950: '#1A93D1',
                  },
              },
              components: {
                  inputtext: {
                      root: {
                          disabledBackground: "#fafafa",
                      },
                  },
              },
          }),
      },
      translation: {
          monthNames: ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december' ],
          monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec' ],
          dayNames: ['söndag', 'måndag', 'tisdag', 'onsdag', 'torsdag', 'fredag', 'lördag'],
          dayNamesShort: ['sön', 'mån', 'tis', 'ons', 'tor', 'fre', 'lör'],
          dayNamesMin: ['sö', 'må', 'ti', 'on', 'to', 'fr', 'lö'],
          weekHeader: 'Vecka',
          firstDayOfWeek: 1,
          clear: 'Rensa',
          choose: 'Välj',
          today: 'Idag'
          //See primeng\api\translation.d.ts
      }
    }),
    provideHttpClient(withInterceptorsFromDi()),
  ],
  exports: [
    MessageComponent,
    MessageListComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
