import { Component } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MessageModel } from "../../../../../models/message.model";
import { AuthService } from "../../../../../services/auth.service";
import { MessageService } from "../../../../../services/message.service";
import { UtilityService } from "../../../../../services/utility.service";
import { MessageFormComponent } from "../message-form.component";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-web-message-form",
  templateUrl: "./web-message-form.component.html",
  styleUrls: ["./../message-form.component.scss"]
})
export class WebMessageFormComponent extends MessageFormComponent  {
  readonly maxLength = {
    body: 100,
    heading: 100,
  };

  constructor(
    protected override authService: AuthService,
    protected override messageService: MessageService,
    protected override activatedRoute: ActivatedRoute
  ) {
    super(authService, messageService, activatedRoute);
  }

  override setUpExistingMessage(val: MessageModel) {
    val.validFrom = new Date(val.validFrom!);
    val.validFrom.setSeconds(0);

    if (UtilityService.validDate(val.validTo!)) {
      val.validTo = new Date(val.validTo!);
      val.validTo.setSeconds(0);
    } else {
      val.validTo = null;
    }

    this._message = val;
    this.setUpFormControls();
  }

  override setUpDefaultValues(val: MessageModel) {
    console.log("setup default values");
    val.validFrom = new Date();
    val.type = "TOT";

    this._message = val;
    this.setUpFormControls();
  }

  setUpFormControls() {
    this.messageForm = new FormGroup({
      body: new FormControl({
        value: this.message.body,
        disabled: this.isReadOnly()
      }),
      heading: new FormControl({
        value: this.message.heading,
        disabled: this.isReadOnly()
      }),
      validFrom: new FormControl({
        value: this.message.validFrom,
        disabled: this.isReadOnly()
      }),
      validTo: new FormControl({
        value: this.message.validTo,
        disabled: this.isReadOnly()
      })
    });

    this.setInitialErrors("heading");
    this.setInitialErrors("body");
  }
}
