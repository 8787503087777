import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AuthService } from "../../../../services/auth.service";
import { MessageModel } from "../../../../models/message.model";
import { environment } from "../../../../../environments/environment";
import { MessageService } from "../../../../services/message.service";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from 'rxjs';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  @Input() message: MessageModel | null = null;
  @Input() numberOfVersions: number = 1;
  @Input() laterMessageExists: boolean = false;
  @Output() changeVersion: EventEmitter<number> = new EventEmitter();
  @Output() saveChanges: EventEmitter<string> = new EventEmitter();
  @Output() closeMessage: EventEmitter<string> = new EventEmitter();
  @Output() useAsTemplate: EventEmitter<string> = new EventEmitter();
  environment = environment;
  showButton: boolean = true;
  checkboxSelected: boolean = false;
  private messageTypeSubscription: Subscription = new Subscription();
  currentMessageType: string = '';

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute
  ) {
    this.messageService.getCheckboxState().subscribe(state => {
      this.checkboxSelected = state;
    });
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(param => {
      this.showButton = !param.has("id");
    });
    if(!this.authService.hasReadWriteGroups()){
      this.authService.getGraphUser();
    }
    this.messageTypeSubscription = this.messageService.currentMessageType.subscribe(type => {
      this.currentMessageType = type;
    });
  }

  ngOnDestroy() {
    this.messageTypeSubscription.unsubscribe();
  }

  isReadOnly() {
    return !this.authService.hasReadWriteGroups();
  }

  onVersionChange(nextVersion: boolean) {
    if (nextVersion) {
      let nextVersionNumber = this.message!.ver + 1;
      if (nextVersionNumber <= this.numberOfVersions) {
        this.changeVersion.emit(nextVersionNumber);
      }
    } else {
      let previousVersionNumber = this.message!.ver - 1;
      if (previousVersionNumber > 0) {
        this.changeVersion.emit(previousVersionNumber);
      }
    }
  }

  onSave() {
    this.saveChanges.emit(this.message?.id!);
  }

  onClose() {
    if (
      !this.messageService.hasChanges() ||
          confirm(
            "Du har osparade ändringar. Vill du verkligen släcka meddelandet och förlora övriga förändringar?"
          )
    ) {
      this.closeMessage.emit(this.message!.id!);
    }
  }

  canClose() {
    return (
      !this.message!.validTo ||
      (this.message!.validTo!.getDate! && this.message!.validTo > new Date())
    );
  }

  onUseAsTemplate() {
    this.messageService.useAsTemplate.emit(this.message!.id!);
  }

  canUpdate(): boolean {
    return this.messageService.hasChanges();
  }

  openNewWindow() {
    let id = this.message?.id;
    if (id) {
      this.messageService.clickOpenInNewWindow.emit(id);
    } else {
      id = this.messageService.getNewMessageID();
      this.messageService.clickOpenInNewWindow.emit("new_message");
    }
    window.open(
      "/trafikinfoPopup/" + id,
      "_blank",
      this.messageService.getNewMessageWindowSpecs()
    );
  }

}
