import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, EMPTY, map, switchMap} from "rxjs";
import {createContactData, deleteContactData, fetchCategoriesData, updateContactData,} from "../categories/categories.action";
import {ContactApiService} from "../../services/contact-api.service";
import {SnackbarService} from "../../services/snackbar.service";

@Injectable()
export class ContactsEffects {
  constructor(private actions$: Actions, private contactsAPI: ContactApiService, private snackbarService: SnackbarService) {}

  createContactData$ = createEffect(() => this.actions$.pipe(
    ofType(createContactData),
    switchMap((props =>
        this.contactsAPI.createContactData(props.contact).pipe(
          map(fetchCategoriesData),
          catchError(() => {
            this.snackbarService.showError("Misslyckades skapa kontakt");
            return EMPTY;
          })
        )
    ))))

  updateContactData$ = createEffect(() => this.actions$.pipe(
    ofType(updateContactData),
    switchMap((props =>
      this.contactsAPI.updateContactData(props.contact).pipe(
        map(fetchCategoriesData),
        catchError(() => {
          this.snackbarService.showError("Misslyckades uppdatera kontakt");
          return EMPTY;
        })
      ))
    )))

  deleteContactData$ = createEffect(() => this.actions$.pipe(
    ofType(deleteContactData),
    switchMap((props =>
        this.contactsAPI.deleteContactData(props.contactId).pipe(
          map(fetchCategoriesData),
          catchError(() => {
            this.snackbarService.showError("Misslyckades radera kontakt");
            return EMPTY;
          })
        )
    ))))
}
