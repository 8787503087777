<navigation-header/>

<div class="contact-container">
  <div>
    <button mat-stroked-button (click)="toggleAllExpanded()">{{ allExpandedState() ? 'Minimera' : 'Expandera' }} alla
      kategorier
      <mat-icon>{{ allExpandedState() ? 'expand_less' : 'expand_more' }}</mat-icon>
    </button>
    <mat-accordion [multi]="true">
      <mat-expansion-panel *ngFor="let category of categories; trackBy: trackById;"
                           (opened)="panelOpenState.add(category.categoryId)"
                           (closed)="panelOpenState.delete(category.categoryId)" [expanded]="allExpandedState()">
        <mat-expansion-panel-header
          [ngClass]="{ 'has-changes': !panelOpenState.has(category.categoryId) && hasChildChanges(category.categoryId) }">
          <mat-panel-title>{{ category.categoryName }}</mat-panel-title>
          <mat-panel-description>{{ category.contacts.length }} kontakter</mat-panel-description>
        </mat-expansion-panel-header>
        <contacts-table [categoryId]="category.categoryId"/>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
