import { MessageInfo } from "./message-info.model";

export class MessageApiModel {
  constructor(
    public body: string | null = null,
    public createdAt: string | null = null,
    public createdBy: string | null = null,
    public heading: string | null = null,
    public id: string | null = null,
    public info: MessageInfo = new MessageInfo(),
    public sendMail: boolean = false,
    public type: string | null = null,
    public updatedAt: string | null = null,
    public updatedBy: string | null = null,
    public validFrom: string | null = null,
    public validTo: string | null = null,
    public ver: number = 1
  ) {}
}
