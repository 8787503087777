import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Injectable} from "@angular/core";
import {ContactModel} from "../models/contactModel";
import {CategoryModel} from "../models/CategoryModel";
import {CategoryApiModel} from "../models/api-category.model";
import {ContactApiModel} from "../models/api-contact.model";

@Injectable({
  providedIn: "root"
})
export class ContactApiService {
  constructor(private httpClient: HttpClient) { }

  fetchCategoriesData() {
    return this.httpClient.get<CategoryModel[]>(`${environment.contactApiBaseUrl}/api/category/contacts`);
  }

  createCategoryData(category: Omit<CategoryApiModel, "id" | "categoryPriorityOrder">) {
    return this.httpClient.post<any>(`${environment.contactApiBaseUrl}/api/category`, category);
  }

  updateCategoryData(category: CategoryApiModel) {
    return this.httpClient.put<any>(`${environment.contactApiBaseUrl}/api/category`, category);
  }

  deleteCategoryData(categoryId: string) {
    return this.httpClient.delete<any>(`${environment.contactApiBaseUrl}/api/category?categoryId=${categoryId}`);
  }

  createContactData(contact: Omit<ContactApiModel, "id" | "groupPriorityOrder">) {
    return this.httpClient.post<any>(`${environment.contactApiBaseUrl}/api/contact`, contact);
  }

  updateContactData(contact: ContactApiModel) {
    return this.httpClient.put<any>(`${environment.contactApiBaseUrl}/api/contact`, contact);
  }

  deleteContactData(contactId: string) {
    return this.httpClient.delete<any>(`${environment.contactApiBaseUrl}/api/contact?contactId=${contactId}`);
  }
}
