<div>
  <filter-box (textChanged)="applyFilter($event)" />
</div>

<mat-table
  cdkDropList
  cdkDropListLockAxis="y"
  (cdkDropListDropped)="drop($event)"
  [dataSource]="formArray"
  [cdkDropListDisabled]="dragDisabled">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <ng-container matColumnDef="position" >
    <mat-header-cell *matHeaderCellDef>Position</mat-header-cell>
    <mat-cell *matCellDef="let element; let index = index">
      <mat-icon [ngClass]="{dragCursor: !element.value.categoryId.startsWith('new_')}" (mousedown)="dragDisabled = element.value.id.startsWith('new_')">reorder</mat-icon>
      <span>{{ index + 1 }} ({{ element.value.groupPriorityOrder }})</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>Kontakt</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span *ngIf="!editingRows.has(element.value.id)">{{ element.value.name }}</span>

      <mat-form-field
        appearance="outline"
        *ngIf="editingRows.has(element.value.id)">
        <mat-label>Kontakt</mat-label>
        <input matInput [formControl]="element.get('name')"/>

        <mat-error *ngIf="element.get('name').hasError('required')">Måste anges</mat-error>
      </mat-form-field>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="description">
    <mat-header-cell *matHeaderCellDef>Beskrivning</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span *ngIf="!editingRows.has(element.value.id)">{{ element.value.description }}</span>
      <mat-form-field appearance="outline" *ngIf="editingRows.has(element.value.id)">
        <mat-label>Beskrivning</mat-label>
        <input matInput [formControl]="element.get('description')"/>
      </mat-form-field>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="category">
    <mat-header-cell *matHeaderCellDef>Kategori</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span *ngIf="!editingRows.has(element.value.id)">{{ getCategoryName(element.value.categoryId) }}</span>
      <mat-form-field appearance="outline" *ngIf="editingRows.has(element.value.id)">
        <mat-label>Kategori</mat-label>
        <mat-select [(value)]="element.get('categoryId').value" [formControl]="element.get('categoryId')">
            <mat-option *ngFor="let option of categoryOptions" [value]="option.value">{{ option.text }}</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="phoneNumber">
    <mat-header-cell *matHeaderCellDef>Telefon</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span *ngIf="!editingRows.has(element.value.id)">{{ element.value.phoneNumber }}</span>
      <mat-form-field appearance="outline" *ngIf="editingRows.has(element.value.id)">
        <mat-label>Telefon</mat-label>
        <input matInput [formControl]="element.get('phoneNumber')"/>

        <mat-error *ngIf="element.get('phoneNumber').hasError('required')">Måste anges</mat-error>
        <mat-error *ngIf="element.get('phoneNumber').hasError('pattern')">Får enbart innehålla siffror, mellanslag och bindestreck. Max 20 tecken</mat-error>
      </mat-form-field>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="phoneNumberNote">
    <mat-header-cell *matHeaderCellDef>Notis</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span *ngIf="!editingRows.has(element.value.id)">{{ element.value.phoneNumberNote }}</span>
      <mat-form-field appearance="outline" *ngIf="editingRows.has(element.value.id)">
        <mat-label>Notis</mat-label>
        <input matInput [formControl]="element.get('phoneNumberNote')"/>
      </mat-form-field>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef>Åtgärder</mat-header-cell>
    <mat-cell *matCellDef="let element;">
      <button mat-icon-button matTooltip="Redigera" *ngIf="!editingRows.has(element.value.id)" (click)="edit(element.value.id)">
        <mat-icon>edit</mat-icon>
      </button>

      <button mat-icon-button matTooltip="Spara" *ngIf="editingRows.has(element.value.id)" (click)="save(element)">
        <mat-icon>save</mat-icon>
      </button>

      <div [matTooltip]="getVisibilityTooltipText(element.value.visible, element.value.id)">
        <button mat-icon-button [formGroup]="element.get('visible')" (click)="setVisibility(element)" [disabled]="!editingRows.has(element.value.id)">
          <mat-icon *ngIf="element.value.visible && editingRows.has(element.value.id)">visibility</mat-icon>
          <mat-icon *ngIf="!element.value.visible">visibility_off</mat-icon>
        </button>
      </div>

      <button mat-icon-button matTooltip="Återställ" *ngIf="editingRows.has(element.value.id)" (click)="reset(element.value.id, element)">
        <mat-icon>cancel</mat-icon>
      </button>

      <button mat-icon-button matTooltip="Radera" *ngIf="editingRows.has(element.value.id)" (click)="delete(element.value.id)">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"/>
  <mat-row
    cdkDrag
    *matRowDef="let row; columns: displayedColumns"
    [cdkDragData]="row"
    [ngClass]="{ changes: row.dirty }"
  />
</mat-table>

<mat-divider />
<div style="display: flex; flex-direction: column; margin-top: 5px;">
  <button mat-flat-button (click)="createNewRow()" style="align-self: flex-end">Skapa ny kontakt</button>
</div>
