import {Component, EventEmitter, Output} from '@angular/core';
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {NgIf} from "@angular/common";

@Component({
  selector: 'filter-search-box',
  templateUrl: './filter-search-box.component.html',
  styleUrls: ['./filter-search-box.component.scss'],
  imports: [
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    NgIf
  ],
  standalone: true
})
export class FilterSearchBoxComponent {
  @Output() textChanged = new EventEmitter<string>();
  filterText: FormControl = new FormControl("");

  constructor() {
    this.filterText.valueChanges.subscribe(value => {
      this.textChanged.emit(value);
    })
  }

  onFilterClear() {
    this.filterText.setValue('');
    this.textChanged.emit('');
  }
}
