import {Component, Inject} from '@angular/core';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MAT_SNACK_BAR_DATA, MatSnackBarConfig, MatSnackBarModule} from "@angular/material/snack-bar";

@Component({
  standalone: true,
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  imports: [
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule
  ]
})
export class SnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: NonNullable<MatSnackBarConfig<{text: string; icon: "error" | "check"}>["data"]>) {}
}
