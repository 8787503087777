import {Component, OnInit, ViewChild} from "@angular/core";
import {CategoryModel, Group} from "../../models/CategoryModel";
import {Store} from "@ngrx/store";
import {selectCategoriesData} from "../../store/categories/categories.selector";
import {Observable} from "rxjs";
import {
  createCategoryData,
  deleteCategoryData,
  fetchCategoriesData,
  updateCategoryData
} from "../../store/categories/categories.action";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {CategoryApiModel} from "../../models/api-category.model";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {SortableTableService} from "../shared-table";

@Component({
  selector: "categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.scss", "./shared.scss"],
})
export class CategoriesComponent implements OnInit {
  @ViewChild(MatTable) table!: MatTable<CategoryModel>;
  categoriesData$: Observable<CategoryModel[]>;
  dragDisabled = true;
  editingRows: Set<CategoryModel["categoryId"]> = new Set();
  displayedColumns: string[] = [
    "position",
    "name",
    "showTo",
    "actions",
  ];
  formArray: MatTableDataSource<FormGroup<{
    categoryId: FormControl<string>;
    categoryName: FormControl<string>;
    group: FormArray<FormControl<Group>>;
    categoryPriorityOrder: FormControl;
  }>> = new MatTableDataSource();

  constructor(private store: Store, private fb: FormBuilder) {
    this.categoriesData$ = this.store.select(selectCategoriesData);
  }

  ngOnInit(): void {
    this.store.dispatch(fetchCategoriesData());

    this.categoriesData$.subscribe(categoriesData => {
      this.formArray.data = [...categoriesData].sort((a, b) => SortableTableService.compare(a.categoryPriorityOrder, b.categoryPriorityOrder, true))
        .map((category, i) => this.fb.group({
          categoryId: this.fb.nonNullable.control(category.categoryId),
          categoryName: this.fb.nonNullable.control(category.categoryName, [Validators.minLength(1)]),
          group: this.fb.nonNullable.array(this.mapGroupFromServer([category.group]), [Validators.minLength(1), Validators.maxLength(1)]),
          categoryPriorityOrder: this.fb.nonNullable.control(category.categoryPriorityOrder),
        }))

      const filterableKeys = ['categoryName'];
      this.formArray.filterPredicate = (data: FormGroup, filter: string) => SortableTableService.filterPredicate(data, filter, filterableKeys);
    })
  }

  drop(event: CdkDragDrop<FormGroup<{
    categoryId: FormControl<string>;
    categoryName: FormControl<string>;
    categoryPriorityOrder: FormControl<number>;
    group: FormArray<FormControl<CategoryModel["group"]>>
  }>, any>) {
    // Return the drag container to be disabled.
    this.dragDisabled = true;
    const { isNew } = SortableTableService.drop(this.formArray, "categoryPriorityOrder", event);
    if (!isNew) {
      this.save(event.item.data);
      this.table.renderRows();
    }
  }

  reset(id: string, element?: AbstractControl) {
    SortableTableService.resetRow(this.editingRows, this.formArray.data, element!, id);
    this.table.renderRows();
  }

  edit(id: string) {
    this.editingRows.add(id);
  }

  delete(categoryId: string) {
    this.deleteCategory(categoryId);
    this.editingRows.delete(categoryId);
  }

  updateCheck(checked: MatCheckboxChange, element: AbstractControl) {
    const groups = element.get('group') as FormArray;

    if (checked.checked) {
      groups.push(new FormControl(checked.source.value));
    } else {
      const findIndex = groups.value.findIndex(group => group === checked.source.value);
      groups.removeAt(findIndex);
    }
    groups.markAsDirty();
  }

  save(rowElement: AbstractControl<CategoryModel>) {
    const contactGroups = rowElement.get('group') as unknown as FormArray;
    const requestPayload = new CategoryApiModel(
      rowElement.value.categoryId,
      rowElement.value.categoryName,
      rowElement.value.categoryPriorityOrder,
      this.mapGroupToServer(contactGroups.value),
    );

    const filteredRequestPayload = SortableTableService.filterRequestPayload(rowElement.value.categoryId, rowElement.value.categoryId, requestPayload);

    if (rowElement.value.categoryId.startsWith('new_')) {
      this.store.dispatch(createCategoryData({category: filteredRequestPayload as Omit<CategoryApiModel, "id" | "categoryPriorityOrder">}));
    } else {
      this.store.dispatch(updateCategoryData({category: filteredRequestPayload as CategoryApiModel}));
    }

    // todo: fetch new data from server, will break other fields being edited?
    rowElement.markAsPristine();
    this.editingRows.delete(rowElement.value.categoryId);
  }

  createNewRow() {
    const newCategoryId = `new_${this.formArray.data.length}`;
    const newRow = this.fb.group({
      categoryId: this.fb.nonNullable.control(newCategoryId),
      categoryName: this.fb.nonNullable.control("", [Validators.minLength(1)]),
      group: this.fb.nonNullable.array<Group>([], [Validators.minLength(1), Validators.maxLength(1)]) ,
      categoryPriorityOrder: this.fb.nonNullable.control(null),
    });
    this.formArray.data = [...this.formArray.data, newRow];

    newRow.markAsDirty();
    this.table.renderRows();

    // todo: enable edit-mode on current row only + focus
    this.editingRows.add(newCategoryId);
  }

  deleteCategory(id: string) {
    this.store.dispatch(deleteCategoryData({ categoryId: id }));
  }

  isChecked(element: FormArray, search: string) {
    return element.value.includes(search) || ['NonTrainDriver', 'TrainDriver'].every((value) => element.value.includes(value));
  }

  mapGroupToServer(element: Omit<Group, "Both">[]): Group {
    if (element.includes("NonTrainDriver") && element.includes("TrainDriver")) {
      return "Both";
    }

    // @ts-ignore todo
    return element[0] || [];
  }

  mapToReadableText(thing: CategoryModel["group"]) {
    switch (thing) {
      case "TrainDriver":
        return "Lokförare"
      case "NonTrainDriver":
        return "Stationsvärd"
      case "Both":
        return "Alla"
    }
  }

  mapToReadableTextArray(thing: Array<FormControl<CategoryModel["group"]>>) {
    return thing.map(t => this.mapToReadableText(t.value))
  }

  mapGroupFromServer(groupsFromServer: Array<"NonTrainDriver" | "TrainDriver" | "Both">): Array<FormControl<CategoryModel["group"]>> {
    if (groupsFromServer.includes("Both")) {
      return [
        this.fb.nonNullable.control("NonTrainDriver"),
        this.fb.nonNullable.control("TrainDriver"),
      ];
    } else {
      return groupsFromServer.map(group => this.fb.nonNullable.control(group));
    }
  }

  applyFilter(filter: string) {
    this.formArray.filter = filter.trim().toLowerCase();
  }
}
