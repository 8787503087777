<div>
  <filter-search-box (textChanged)="applyFilter($event)" />
</div>

<table
  mat-table
  cdkDropList
  (cdkDropListDropped)="onDrop($event)"
  [cdkDropListDisabled]="dragDisabled"
  [dataSource]="tableSource">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <ng-container matColumnDef="position" >
    <th mat-header-cell *matHeaderCellDef>Position</th>
    <td mat-cell *matCellDef="let element">
      <div>
        <mat-icon [ngClass]="{'move-cursor': !PhonebookTableService.isNewEntry(element.value)}"
                  (mousedown)="dragDisabled = PhonebookTableService.isNewEntry(element.value)">reorder</mat-icon>
        <span>{{ element.value.priorityOrder }}</span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Kontakt</th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="!editingRows.includes(element.value.id)">{{ element.value.name }}</span>

      <mat-form-field
        appearance="outline"
        *ngIf="editingRows.includes(element.value.id)">
        <mat-label>Kontakt</mat-label>
        <input matInput [formControl]="element.controls.name"/>

        <mat-error *ngIf="element.controls.name.hasError('required')">Måste anges</mat-error>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>Beskrivning</th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="!editingRows.includes(element.value.id)">{{ element.value.description }}</span>
      <mat-form-field appearance="outline" *ngIf="editingRows.includes(element.value.id)">
        <mat-label>Beskrivning</mat-label>
        <input matInput [formControl]="element.controls.description"/>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef>Kategori</th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="!editingRows.includes(element.value.id)">{{ getCategoryName(element.value.categoryId) }}</span>
      <mat-form-field appearance="outline" *ngIf="editingRows.includes(element.value.id)">
        <mat-label>Kategori</mat-label>
        <mat-select [(value)]="element.value.categoryId" [formControl]="element.controls.categoryId">
            <mat-option *ngFor="let option of categoryOptions()" [value]="option.value">{{ option.text }}</mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="phoneNumber">
    <th mat-header-cell *matHeaderCellDef>Telefon</th>
    <td mat-cell *matCellDef="let element">
      <div>
        <span *ngIf="!editingRows.includes(element.value.id)">{{ element.value.phoneNumber }}</span>
        <mat-form-field appearance="outline" *ngIf="editingRows.includes(element.value.id)">
          <mat-label>Telefon</mat-label>
          <input matInput [formControl]="element.controls.phoneNumber"/>

          <mat-error *ngIf="element.controls.phoneNumber.hasError('required')">Måste anges</mat-error>
          <mat-error *ngIf="element.controls.phoneNumber.hasError('pattern')">Får enbart innehålla siffror, mellanslag och bindestreck. Max 20 tecken</mat-error>
        </mat-form-field>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="phoneNumberNote">
    <th mat-header-cell *matHeaderCellDef>Notis</th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="!editingRows.includes(element.value.id)">{{ element.value.phoneNumberNote }}</span>
      <mat-form-field appearance="outline" *ngIf="editingRows.includes(element.value.id)">
        <mat-label>Notis</mat-label>
        <input matInput [formControl]="element.controls.phoneNumberNote"/>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Åtgärder</th>
    <td mat-cell *matCellDef="let element">
        <div>
          <button mat-icon-button color="primary" matTooltip="Spara" *ngIf="editingRows.includes(element.value.id)"
                  (click)="save(element, 'Regular')">
            <mat-icon>save</mat-icon>
          </button>

          <button mat-icon-button matTooltip="Radera" *ngIf="editingRows.includes(element.value.id)"
                  (click)="delete(element.value)">
            <mat-icon>delete_forever</mat-icon>
          </button>

          <div [matTooltip]="getVisibilityTooltipText(element.value.visible, element.value.id)">
            <button mat-icon-button [formGroup]="element.controls.visible" (click)="setVisibility(element)"
                    [disabled]="!editingRows.includes(element.value.id)">
              <mat-icon *ngIf="element.value.visible && editingRows.includes(element.value.id)">visibility</mat-icon>
              <mat-icon *ngIf="!element.value.visible">visibility_off</mat-icon>
            </button>
          </div>

          <button mat-icon-button matTooltip="Redigera" *ngIf="!editingRows.includes(element.value.id)"
                  (click)="edit(element.value.id)">
            <mat-icon>edit</mat-icon>
          </button>

          <button mat-icon-button matTooltip="Återställ" *ngIf="editingRows.includes(element.value.id)"
                  (click)="reset(element.value.id, element)">
            <mat-icon>undo</mat-icon>
          </button>
        </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row
      cdkDrag
      [cdkDragData]="row"
      [ngClass]="{ 'has-changes': row.dirty }"
      *matRowDef="let row; columns: displayedColumns"
  ></tr>
</table>

<mat-divider />

<div class="footer">
  <button mat-flat-button color="primary" (click)="createNewContactRow()" class="footer__button">Skapa ny kontakt<mat-icon>person_add</mat-icon></button>
</div>
