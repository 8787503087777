import {Injectable} from "@angular/core";
import {MessageModel} from "../models/message.model";
import {MessageApiModel} from "../models/api-message.model";
import {MessageApiFilter} from "../models/api-message-filter.model";
import {MessageFilter} from "../models/message-filter.model";
import {AbstractControl, FormArray, FormGroup} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {ContactApiModel} from "../models/api-contact.model";
import {CategoryApiModel} from "../models/api-category.model";

@Injectable({
  providedIn: "root"
})
export class SortableTableService {
  constructor() {
  }


  public static resetRow(editingRows: Set<string | null>, formArray: FormGroup[], element: AbstractControl, id: string) {
    editingRows.delete(id);

    // Remove new row when press cancel
    if (id.startsWith('new_')) {
      const newId = formArray.findIndex(item => item.get('id')?.value === id)
      if (newId) {
        formArray.splice(newId, 1);
      }
    } else {
      element?.reset();
    }
  }

  public static filterPredicate(data: FormGroup, filter: string, filterableKeys: string[]) {
    return !filter || filter.split(' ').every(word => {
      return filterableKeys.some(key => data.value[key].toLowerCase().includes(word.toLowerCase()));
    });
  }

  public static filterRequestPayload(parentCategoryId: string, rowId: string, requestPayload: ContactApiModel | CategoryApiModel, rowCategoryId?: string) {
    let result = requestPayload;

    // Remove groupPriorityOrder when the category option has changed to another categoryId.
    // Only relevant when changing on ContactApiModal
    if (rowCategoryId && rowCategoryId !== parentCategoryId) {
      if ("groupPriorityOrder" in result) {
        const { groupPriorityOrder, ...withoutPriority} = result;
        result = withoutPriority as ContactApiModel;
      }
    }

    if (rowId.startsWith('new_')) {
      // When creating a new entry we do not want to send "id" and "priorityOrder", since those are set by the backend.
      console.log('nullify id')
      if ("groupPriorityOrder" in result) {
        const {id, groupPriorityOrder, ...bodyWithoutId} = result;
        return bodyWithoutId;
      } else {
        const {id, categoryPriorityOrder, ...bodyWithoutId} = result;
        return bodyWithoutId;
      }
    }

    return result;
  }

  public static compare(a: number, b: number, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public static drop(formArray: MatTableDataSource<FormGroup>, priorityKeyName: "groupPriorityOrder" | "categoryPriorityOrder", event: CdkDragDrop<FormGroup>) {
    // Return the drag container to be disabled.
    const droppedRowElement = formArray.data.at(event.currentIndex);
    const newPosition: number = droppedRowElement?.value[priorityKeyName];
    moveItemInArray(formArray.data, event.previousIndex, event.currentIndex);

    // Assign new groupPriorityOrder it should be saved with in backend.
    event.item.data.get(priorityKeyName).patchValue(newPosition);

    const id = event.item.data.value.categoryId || event.item.data.value.id;
    return { isNew: id.startsWith('new_') }
  }


}
