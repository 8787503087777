import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CategoriesState } from "./categories.reducer"

export const categoriesFeatureKey = 'categoriesFeatureKey';
export const selectContactCategoriesFeature = createFeatureSelector<CategoriesState>(categoriesFeatureKey)

export const selectCategoriesData = createSelector(
  selectContactCategoriesFeature,
  state => state.categoriesData
)

export const selectEditingRowsData = createSelector(
  selectContactCategoriesFeature,
  state => state.editingRowsData
)
