import {Component, EventEmitter, Output} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'filter-box',
  templateUrl: './filter-box.component.html',
  styleUrls: ['./filter-box.component.scss'],
})
export class FilterBoxComponent {
  @Output() textChanged = new EventEmitter<string>();
  filterText: FormControl = new FormControl("");

  constructor() {
    this.filterText.valueChanges.subscribe(value => {
      this.textChanged.emit(value);
    })
  }

  onFilterClear() {
    this.filterText.setValue('');
    this.textChanged.emit('');
  }
}
