import {createAction, props} from "@ngrx/store";
import {CategoryModel} from "../../models/CategoryModel";
import {CategoryApiModel} from "../../models/api-category.model";
import {ContactApiModel} from "../../models/api-contact.model";

export const fetchCategoriesData = createAction(
  '[Contacts] Fetch Categories Data'
)

export const updateCategoriesData = createAction(
  '[Contacts] Update Categories Data',
  props<{ categoriesData: CategoryModel[] }>()
)

export const updateCategoryData = createAction(
  '[Category] Update existing category (on server)',
  props<{ category: CategoryApiModel }>()
)

export const createCategoryData = createAction(
  '[Category] Create a new category (on server)',
  props<{ category: Omit<CategoryApiModel, "id" | "categoryPriorityOrder"> }>()
)

export const deleteCategoryData = createAction(
  '[Category] Delete a category (on server)',
  props<{ categoryId: string }>()
)

export const updateContactData = createAction(
  '[Contact] Update existing category (on server)',
  props<{ contact: ContactApiModel }>()
)

export const createContactData = createAction(
  '[Contact] Create a new contact (on server)',
  props<{ contact: Omit<ContactApiModel, "id" | "groupPriorityOrder"> }>()
)

export const deleteContactData = createAction(
  '[Contact] Delete a contact (on server)',
  props<{ contactId: string }>()
)

