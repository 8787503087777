import {Injectable} from "@angular/core";
import {AbstractControl, FormArray, FormGroup} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {ContactApiModel} from "../models/api-contact.model";
import {CategoryApiModel} from "../models/api-category.model";
import {CategoryModel, Contact} from "../models/CategoryModel";

@Injectable({
  providedIn: "root"
})
export class PhonebookService {

  public static resetRow(formArray: FormArray<FormGroup>, element: AbstractControl, id: string) {
    // Remove new row when press cancel
    if (this.isNewEntry(element.value)) {
      const newId = formArray.value.findIndex(item => {
        return [item.id, item.categoryId].includes(id);
      })
      if (newId >= 0) {
        formArray.removeAt(newId);
      }
    } else {
      element?.reset();
    }
  }

  public static filterPredicate(data: FormGroup, filter: string, filterableKeys: string[]) {
    return !filter || filter.split(' ').every(word => {
      return filterableKeys.some(key => data.value[key].toLowerCase().includes(word.toLowerCase()));
    });
  }

  public static filterRequestPayload(parentCategoryId: string, rowId: string, requestPayload: ContactApiModel | CategoryApiModel, rowCategoryId?: string) {
    let result = requestPayload;

    // Remove priorityOrder when the category option has changed to another categoryId.
    // Only relevant when changing on ContactApiModal
    if (rowCategoryId && rowCategoryId !== parentCategoryId) {
      if ("priorityOrder" in result) {
        const { priorityOrder, ...withoutPriority} = result;
        result = withoutPriority as ContactApiModel;
      }
    }

    if (rowId.startsWith('new_')) {
      // When creating a new entry we do not want to send "id" and "priorityOrder", since those are set by the backend.
      if ("priorityOrder" in result) {
        const {id, priorityOrder, ...bodyWithoutId} = result;
        return bodyWithoutId;
      } else {
        const {id, categoryPriorityOrder, ...bodyWithoutId} = result;
        return bodyWithoutId;
      }
    }

    return result;
  }

  public static compare(a: number, b: number, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public static isNewEntry(rowElement: Contact | CategoryModel) {
    const id = "id" in rowElement ? rowElement.id : rowElement.categoryId;
    return id.startsWith('new_');
  }

  public static onDrop(formArray: MatTableDataSource<FormGroup>, priorityKeyName: "priorityOrder" | "categoryPriorityOrder", event: CdkDragDrop<FormGroup>) {
    // Return the drag container to be disabled.
    const droppedRowElement = formArray.data.at(event.currentIndex);
    const newPosition: number = droppedRowElement?.value[priorityKeyName];
    moveItemInArray(formArray.data, event.previousIndex, event.currentIndex);

    // Assign the new priorityOrder the row should be saved with on server.
    event.item.data.get(priorityKeyName).patchValue(newPosition);

    return { isNew: this.isNewEntry(event.item.data.value) }
  }

}
