import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, EMPTY, map, of, switchMap} from "rxjs";
import {
  addEditingRowsData,
  createContactData,
  deleteContactData,
  fetchCategoriesData,
  removeEditingRowsData,
  updateContactData,
} from "../categories/categories.action";
import {ContactApiService} from "../../services/contact-api.service";
import {SnackbarService} from "../../services/snackbar.service";
import {Store} from "@ngrx/store";

@Injectable()
export class ContactsEffects {
  constructor(private actions$: Actions, private store: Store, private contactsAPI: ContactApiService, private snackbarService: SnackbarService) {}

  createContactData$ = createEffect(() => this.actions$.pipe(
    ofType(createContactData),
    switchMap((props =>
        this.contactsAPI.createContactData(props.contact).pipe(
          map(() => {
            this.snackbarService.showSuccess("Kontakt sparad!");
            this.store.dispatch(removeEditingRowsData({rowId: props.id}))
            return fetchCategoriesData();
          }),
          catchError(() => {
            this.snackbarService.showError("Misslyckades skapa kontakt");
            return of(fetchCategoriesData());
          })
        )
    ))))

  updateContactData$ = createEffect(() => this.actions$.pipe(
    ofType(updateContactData),
    switchMap((props =>
      this.contactsAPI.updateContactData(props.contact).pipe(
        map(() => {
          this.snackbarService.showSuccess("Kontakt uppdaterad!");
          this.store.dispatch(removeEditingRowsData({rowId: props.contact.id!}))
          return fetchCategoriesData();
        }),
        catchError(() => {
          this.snackbarService.showError("Misslyckades uppdatera kontakt");

          if (props.saveMethod === "Regular") {
            // When dropping we do not want to enable edit mode. Only when doing a failed "edit"-save
            this.store.dispatch(addEditingRowsData({rowId: props.contact.id!}))
            return EMPTY;
          }

          // When a drop fails to save we do not want to be in edit mode,
          // fetch all data again to send the row back to original placement
          this.store.dispatch(removeEditingRowsData({rowId: props.contact.id!}))
          return of(fetchCategoriesData());
        })
      ))
    )))

  deleteContactData$ = createEffect(() => this.actions$.pipe(
    ofType(deleteContactData),
    switchMap((props =>
        this.contactsAPI.deleteContactData(props.contactId).pipe(
          map(() => {
            this.snackbarService.showSuccess("Kontakt raderad!");
            return fetchCategoriesData();
          }),
          catchError(() => {
            this.snackbarService.showError("Misslyckades radera kontakt");
            return of(fetchCategoriesData());
          })
        )
    ))))
}
