import { Component } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MessageModel } from "../../../../../models/message.model";
import { AuthService } from "../../../../../services/auth.service";
import { MessageService } from "../../../../../services/message.service";
import { UtilityService } from "../../../../../services/utility.service";
import { MessageFormComponent } from "../message-form.component";
import { NotifyGroupsService } from "src/app/services/notify-groups.service";

@Component({
  selector: "app-app-message-form",
  templateUrl: "./app-message-form.component.html",
  styleUrls: ["./../message-form.component.scss"]
})
export class AppMessageFormComponent extends MessageFormComponent {
  readonly maxLength = {
    body: 500,
    heading: 100,
    affects: 50,
    location: 50,
    action: 2000,
    alternativeRoute: 4000,
    estimation: 200,
    incident: 1000,
    trafficInfo: 4000,
  };

  notifyGroupsList = NotifyGroupsService.getnotifyGroupsList();
  ticketCooperation: boolean = false;
  replacementBusOrdered: boolean = false;

  replacementBusConfirmed: boolean = false;
  allPSelected = false;
  silentMtrp: boolean = false;
  variousSelected = false;

  constructor(
    protected override authService: AuthService,
    protected override messageService: MessageService,
    protected override activatedRoute: ActivatedRoute
  ) {
    super(authService, messageService, activatedRoute);
  }

  override ngOnInit() {
    this.messageService.changeMessageType('TI');
  }

  override setUpExistingMessage(val: MessageModel) {
    this.setUpInitialValues();

    val.validFrom = new Date(val.validFrom!);
    val.validFrom.setSeconds(0);
    if (UtilityService.validDate(val.validTo!)) {
      val.validTo = new Date(val.validTo!);
      val.validTo.setSeconds(0);
    } else {
      val.validTo = null;
    }
    this._message = val;

    this.setUpFormControls();

    this.replacementBusConfirmed = this.message.info.replacementBusConfirmed;
    this.replacementBusOrdered = this.message.info.replacementBusOrdered;
    this.ticketCooperation = this.message.info.ticketCooperation;
    this.silentMtrp = this.message.info.silentMtrp;

    //Map groups P-SV/OTS, P-KSV to combo group P-SV/KSV/OTS when showing existing message in UI
    if (NotifyGroupsService.mapIndividualGroupsToAggregateGroup(this.message.info.notifyGroups)) {
      //Since the message has been updated by the mapping function, we need to update the originalMessage
      //so that the change detection doesn't treat the original incoming message with groups P-SV/OTS, P-KSV
      //as different from the message with the mapped group P-SV/KSV/OTS
      this.messageService.originalMessage = UtilityService.createMessageCopy(this.message);
    }

    let pendelGroup = this.message.info.notifyGroups;
    if (pendelGroup) {
      if (pendelGroup.length == this.notifyGroupsList.length) {
        this.allPSelected = true;
      }
      pendelGroup.forEach(x => {
        let group = this.notifyGroupsList.find(m => m.value === x);
        if (group) {
          group.selected = true;
        }
      });
    }
  }

  private setUpInitialValues() {
    this.allPSelected = false;
    this.notifyGroupsList = this.notifyGroupsList.map(x => ({...x, selected: false}));
  }

  override setUpDefaultValues(val: MessageModel) {
    console.log("setup default values");
    this.setUpInitialValues();
    val.validFrom = new Date();
    val.validFrom.setSeconds(0);
    val.type = "TI";

    this._message = val;

    this.setUpFormControls();
  }

  setUpFormControls() {
    this.messageForm = new FormGroup({
      body: new FormControl({
        value: this.message.body,
        disabled: this.isReadOnly()
      }),
      heading: new FormControl(
        {
          value: this.message.heading,
          disabled: this.isReadOnly()
        },
        [Validators.required]
      ),
      validFrom: new FormControl({
        value: this.message.validFrom,
        disabled: this.isReadOnly()
      }),
      validTo: new FormControl({
        value: this.message.validTo,
        disabled: this.isReadOnly()
      }),
      affects: new FormControl({
        value: this.message.info.affects,
        disabled: this.isReadOnly()
      }),
      location: new FormControl({
        value: this.message.info.location,
        disabled: this.isReadOnly()
      }),
      action: new FormControl({
        value: this.message.info.action,
        disabled: this.isReadOnly()
      }),
      alternativeRoute: new FormControl({
        value: this.message.info.alternativeRoute,
        disabled: this.isReadOnly()
      }),
      estimation: new FormControl({
        value: this.message.info.estimation,
        disabled: this.isReadOnly()
      }),
      incident: new FormControl({
        value: this.message.info.incident,
        disabled: this.isReadOnly()
      }),
      replacementBusConfirmed: new FormControl({
        value: this.message.info.replacementBusConfirmed,
        disabled: this.isReadOnly()
      }),
      replacementBusOrdered: new FormControl({
        value: this.message.info.replacementBusOrdered,
        disabled: this.isReadOnly()
      }),
      ticketCooperation: new FormControl({
        value: this.message.info.ticketCooperation,
        disabled: this.isReadOnly()
      }),
      trafficInfo: new FormControl({
        value: this.message.info.trafficInfo,
        disabled: this.isReadOnly()
      }),
      notifyGroups: new FormControl({
        value: this.message.info.notifyGroups,
        disabled: this.isReadOnly()
      }),
      silentMtrp: new FormControl({
        value: this.message.info.silentMtrp,
        disabled: this.isReadOnly()
      })
    });
    this.setInitialErrors("heading");
    if (this.message.heading && this.message.info.notifyGroups.length === 0) {
        this.variousSelected = true;
    } else {
        this.variousSelected = false;
    }
  }

  togglePerOne(group) {
    const toggledValue = group.value;
    group.selected = !group.selected;
    let indexOfValue = this.messageForm.controls.notifyGroups.value.indexOf(toggledValue);
    if (indexOfValue >= 0) {
        this.messageForm.controls.notifyGroups.value.splice(indexOfValue, 1);
    } else {
        this.messageForm.controls.notifyGroups.value.push(toggledValue);
    }
    this.allPSelected = this.notifyGroupsList.every(g => g.selected);
    this.variousSelected = false;
    this.updateCheckboxState();
  }

  toggleAllPSelection() {
    this.allPSelected = !this.allPSelected;
    if (this.allPSelected) {
        this.notifyGroupsList.forEach(x => {
            if (!this.messageForm.controls.notifyGroups.value.includes(x.value)) {
                this.messageForm.controls.notifyGroups.value.push(x.value);
            }
        });
    } else {
        this.notifyGroupsList.forEach(x => {
            let index = this.messageForm.controls.notifyGroups.value.indexOf(x.value);
            if (index >= 0) {
                this.messageForm.controls.notifyGroups.value.splice(index, 1);
            }
        });
    }
    this.notifyGroupsList = this.notifyGroupsList.map(x => ({...x, selected: this.allPSelected}));
    this.variousSelected = false; 
    this.updateCheckboxState();
  }

  toggleVarious() {
    this.variousSelected = !this.variousSelected;
    this.messageService.variousSelected = this.variousSelected;

    if (this.variousSelected) {
        this.notifyGroupsList.forEach(group => {
            let index = this.messageForm.controls.notifyGroups.value.indexOf(group.value);
            if (index >= 0) {
                this.messageForm.controls.notifyGroups.value.splice(index, 1);
            }
            group.selected = false;
        });
    } else {
        let index = this.messageForm.controls.notifyGroups.value.indexOf('Övrigt');
        if (index >= 0) {
            this.messageForm.controls.notifyGroups.value.splice(index, 1);
        }
    }
    this.allPSelected = false; 
    this.updateCheckboxState();
  }

  onToggle(formControlName: string) {
    this[formControlName] = !this[formControlName];
    this.messageForm.get(formControlName)!.setValue(this[formControlName]);
    this.message.info[formControlName] = this[formControlName];
    this.updateCheckboxState();
  }

  private updateCheckboxState() {
    const anySelected = this.notifyGroupsList.some(group => group.selected) || this.variousSelected;
    this.messageService.setCheckboxState(anySelected);
  }
}
