import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'SJP.TI.AmigoWeb.Hope';
  showHeader: boolean = true;
   constructor(private primengConfig: PrimeNGConfig) {}

   ngOnInit() {
       this.primengConfig.setTranslation({
        monthNames: ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december' ],
        monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec' ],
        dayNames: ['söndag', 'måndag', 'tisdag', 'onsdag', 'torsdag', 'fredag', 'lördag'],
        dayNamesShort: ['sön', 'mån', 'tis', 'ons', 'tor', 'fre', 'lör'],
        dayNamesMin: ['sö', 'må', 'ti', 'on', 'to', 'fr', 'lö'],
        weekHeader: 'Vecka',
        firstDayOfWeek: 1,
        clear: 'Rensa',
        choose: 'Välj',
        today: 'Idag'
        //See primeng\api\translation.d.ts
       });
   }
}
