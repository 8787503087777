import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {CategoryModel} from "../../../models/CategoryModel";
import {Store} from "@ngrx/store";
import {
  createContactData,
  deleteContactData,
  fetchCategoriesData,
  updateContactData
} from "../../../store/categories/categories.action";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {selectCategoriesData, selectCategoryById} from "../../../store/categories/categories.selector";
import {ContactModel} from "../../../models/contactModel";
import {SortableTableService} from "../../shared-table";
import {ContactApiModel} from "../../../models/api-contact.model";

@Component({
  selector: "contact-sortable-table",
  templateUrl: "./contact-sortable-table.component.html",
  styleUrls: ["./contact-sortable-table.component.scss", './../../categories/shared.scss'],
})
export class ContactSortableTableComponent implements OnInit {
  @Input() categoryId!: string;
  @ViewChild(MatTable) table!: MatTable<ContactModel>;
  categoriesData$: Observable<CategoryModel[]>;
  categoryIdData$: Observable<CategoryModel | undefined>;
  categories!: CategoryModel[];

  dragDisabled = true;
  editingRows: Set<ContactModel["categoryId"]> = new Set();
  displayedColumns: string[] = [
    "position",
    "name",
    "description",
    "category",
    "phoneNumber",
    "phoneNumberNote",
    "actions",
  ];
  formArray: MatTableDataSource<FormGroup<{
    id: FormControl<string>;
    name: FormControl<string>;
    description: FormControl<string>;
    phoneNumber: FormControl<string>;
    phoneNumberNote: FormControl<string>;
    groupPriorityOrder: FormControl;
    categoryId: FormControl<string>;
    visible: FormControl<boolean>;
  }>> = new MatTableDataSource();
  categoryOptions: Array<{ value: string; text: string }> = [];

  constructor(private store: Store, private fb: FormBuilder) {
    this.categoriesData$ = this.store.select(selectCategoriesData);
    this.categoryIdData$ = this.store.select(selectCategoryById(this.categoryId));
  }

  ngOnInit(): void {
    this.store.dispatch(fetchCategoriesData());
    console.log(this.editingRows.values())

    this.categoriesData$.subscribe(categoriesData => {
      if (!categoriesData.length) return;
      this.categories = categoriesData;
      this.categoryOptions = categoriesData.map(category => ({
        value: category.categoryId,
        text: category.categoryName,
      }))
      const categoryContacts = categoriesData.find(category => category.categoryId === this.categoryId)?.contacts || [];
      this.formArray.data = [...categoryContacts]
        .sort((a, b) => SortableTableService.compare(a.groupPriorityOrder, b.groupPriorityOrder, true))
        .map((contact) => {
          return this.generateContactWithValidation({
            id: contact.id,
            name: contact.name,
            description: contact.description || '',
            categoryId: this.categoryId,
            visible: contact.visible,
            phoneNumber: contact.phoneNumber,
            phoneNumberNote: contact.phoneNumberNote || '',
            groupPriorityOrder: contact.groupPriorityOrder,
          });
        });

      const filterableKeys = ['name', 'description', 'phoneNumber', 'phoneNumberNote'];
      this.formArray.filterPredicate = (data: FormGroup, filter: string) => SortableTableService.filterPredicate(data, filter, filterableKeys);
    })
  }

  getCategoryName(categoryId: string) {
    return this.categories.find(category => category.categoryId === categoryId)?.categoryName;
  }

  drop(event: CdkDragDrop<FormGroup>) {
    // Return the drag container to be disabled.
    this.dragDisabled = true;
    const { isNew } = SortableTableService.drop(this.formArray, "groupPriorityOrder", event);
    if (!isNew) {
      this.save(event.item.data);
      this.table.renderRows();
    }
  }

  reset(id: string, element?: AbstractControl) {
    SortableTableService.resetRow(this.editingRows, this.formArray.data, element!, id);
    this.table.renderRows();
  }

  edit(id: string) {
    this.editingRows.add(id);
  }

  delete(contactId: string) {
    this.deleteContact(contactId);
    this.editingRows.delete(contactId);
  }

  save(rowElement: AbstractControl<CategoryModel["contacts"][number]>) {
    // validate
    rowElement.markAllAsTouched();
    if (rowElement.invalid) {
      console.log('Errors!')
      return;
    }

    const requestPayload = new ContactApiModel(
      rowElement.value.id,
      rowElement.value.name,
      rowElement.value.description,
      rowElement.value.phoneNumber,
      rowElement.value.phoneNumberNote,
      rowElement.value.categoryId,
      rowElement.value.visible,
      rowElement.value.groupPriorityOrder,
    )

    const filteredRequestPayload = SortableTableService.filterRequestPayload(rowElement.value.categoryId, rowElement.value.id, requestPayload, this.categoryId);

    if (rowElement.value.id.startsWith('new_')) {
      this.store.dispatch(createContactData({ contact: filteredRequestPayload as Omit<ContactApiModel, "id" | "groupPriorityOrder"> }));
    } else {
      this.store.dispatch(updateContactData({ contact: filteredRequestPayload as ContactApiModel }));
    }

    // todo: fetch new data from server, will break other fields being edited?
    rowElement.markAsPristine();
    this.editingRows.delete(rowElement.value.id);
  }

  generateContactWithValidation(contact: ContactModel) {
    return this.fb.group({
      id: this.fb.nonNullable.control(contact.id),
      name: this.fb.nonNullable.control(contact.name, [Validators.required]),
      description: this.fb.nonNullable.control(contact.description),
      categoryId: this.fb.nonNullable.control(this.categoryId),
      visible: this.fb.nonNullable.control(contact.visible),
      phoneNumber: this.fb.nonNullable.control(contact.phoneNumber, [Validators.required, Validators.pattern(/^\S[\d -]{1,20}$/)]),
      phoneNumberNote: this.fb.nonNullable.control(contact.phoneNumberNote),
      groupPriorityOrder: this.fb.nonNullable.control(contact.groupPriorityOrder),
    })
  }

  createNewRow() {
    const newCategoryId = `new_${this.formArray.data.length}`;
    const emptyContact: ContactModel = {
      id: newCategoryId,
      name: "",
      description: "",
      categoryId: this.categoryId,
      visible: true,
      phoneNumber: "",
      phoneNumberNote: "",
      groupPriorityOrder: null,
    };
    const newEmptyContact = this.generateContactWithValidation(emptyContact);
    newEmptyContact.markAsDirty();
    this.formArray.data = [...this.formArray.data, newEmptyContact];
    this.table.renderRows();

    // todo: enable edit-mode on current row only + focus
    this.editingRows.add(newCategoryId);
  }

  deleteContact(id: string) {
    this.store.dispatch(deleteContactData({ contactId: id }));
  }

  applyFilter(filter: string) {
    this.formArray.filter = filter.trim().toLowerCase();
  }

  setVisibility(element: AbstractControl) {
    element.get('visible')?.patchValue(!element.value.visible);
    this.save(element);
  }

  getVisibilityTooltipText(visible: boolean, contactId: string) {
    if (this.editingRows.has(contactId)) {
      return visible ? 'Dölj kontakt' : 'Aktivera dold kontakt'
    }
    return visible ? '' : 'Kontakten är dold'
  }
}
