import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { filter, Subject, takeUntil } from "rxjs";
import { when } from "q";
import { userReadWriteGroups } from "../../assets/user-groups";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private readonly _destroying$ = new Subject<void>();
  public isIframe: boolean = false;
  public loggedIn: boolean = false;
  baseGraphUrl = "https://graph.microsoft.com/v1.0";

  graphUser: any;
  graphUserGroups: any;

  constructor(
    private httpClient: HttpClient,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private router: Router
  ) {
    this.isIframe = window !== window.parent && !window.opener;

    this.setupLoginListeners();
  }

  setupLoginListeners() {
    this.msalService.handleRedirectObservable().pipe(
      takeUntil(this._destroying$)
    ).subscribe(
      (resp) => {
        if (this.getActiveAccount() === null && !resp) {
          this.msalService.loginRedirect()
        } else {
          console.log("Active user: " + this.msalService.instance.getActiveAccount()?.name)
          this.loggedIn = true;
          this.getGraphUser();
        }
      }
    )

    this.msalBroadcastService.msalSubject$.pipe(
      filter((eventMsg: EventMessage) => eventMsg.eventType === EventType.LOGIN_FAILURE),
      takeUntil(this._destroying$)
    )
      .subscribe(eventMsg => {
        console.log("login failure " + JSON.stringify(eventMsg), eventMsg);
        this.loggedIn = false;
      });

    this.msalBroadcastService.msalSubject$.pipe(
      filter((eventMsg: EventMessage) => eventMsg.eventType === EventType.LOGIN_SUCCESS),
      takeUntil(this._destroying$)
    )
      .subscribe(eventMsg => {
        const authResult = eventMsg.payload as AuthenticationResult
        this.msalService.instance.setActiveAccount(authResult.account)
        // Check if user has correct tenant and client, else not logged in
        const user = this.getActiveAccount();
        const tokenTenant = user!.idTokenClaims!["tid"];
        const tokenClient = user!.idTokenClaims!["aud"];
        if (!tokenTenant ||
          !tokenClient ||
          tokenTenant !== environment.tenantID ||
          tokenClient !== environment.clientID) {
          // Incorrect client/tenant, NOT logged in
          this.loggedIn = true;
        } else {
          // Correct client/tenant, logged in
          this.loggedIn = true;
        }
      });

    this.msalBroadcastService.msalSubject$.pipe(
      filter((eventMsg: EventMessage) => eventMsg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      takeUntil(this._destroying$)
    )
      .subscribe((eventMsg) => {
        this.loggedIn = true;
      })

    this.msalBroadcastService.msalSubject$.pipe(
      filter((eventMsg: EventMessage) => eventMsg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
      takeUntil(this._destroying$)
    )
      .subscribe(eventMsg => {
        if (this.getActiveAccount() == null) {
          this.msalService.loginRedirect();
          this.loggedIn = false;
        }
      });
  }

  getActiveAccount() {
    return this.msalService.instance.getActiveAccount()
  }

  logout() {
    this.msalService.logout();
  }

  getUserName() {
    return this.getActiveAccount() ? this.getActiveAccount()?.name : "";
  }

  getUserNameAndEmail() {
    return this.getActiveAccount() ? (this.getActiveAccount()?.name + " (" + this.getActiveAccount()?.username + ")") : "";
  }

  getGraphUser() {

    this.httpClient.get(`${this.baseGraphUrl}/me`).subscribe({
      next: (data) => {
        this.graphUser = data;
        when(this.graphUser == data).then(() => this.fetchGraphUserGroups());
      },
      error: (error) => {
        console.error(
          " Http get request to MS Graph failed" + JSON.stringify(error)
        );
      }
    });
  }

  fetchGraphUserGroupsHandler(response: any){
      this.graphUserGroups = response;
      console.log("Is DLI", this.hasReadWriteGroups(userReadWriteGroups.DLI));
  }

  handleError(error: any){
    console.error(error);
  }

  fetchGraphUserGroups() {
    this.httpClient
      .get(`${this.baseGraphUrl}/users/${this.graphUser.id}/memberOf`)
      .subscribe({
        next: this.fetchGraphUserGroupsHandler.bind(this),
        error: this.handleError.bind(this)
      });
  }

  hasReadWriteGroups(groups: string[] = userReadWriteGroups.DLI) {
    if (this.graphUserGroups?.value) {
      return this.graphUserGroups.value.findIndex(x => {
          return groups.includes(x.id);
        }) >= 0;
    } else {
      return false;
    }
  }

  hasReadGroups(groups: string[]) {
    // readonly is only relevant for DLI/Trafikinfo page.
    // On kontakter page we want this to return false because that has no read access
    return groups === userReadWriteGroups.DLI;
  }

  async hasGroupsAsync(groups: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(
          `${this.baseGraphUrl}/users/${this.getActiveAccount()!.idTokenClaims!["oid"]}/memberOf`
        )
        .subscribe({
          next: (data) => {
            this.graphUserGroups = data;
            console.log("reached point, RW:", this.hasReadWriteGroups(groups), "R:", this.hasReadGroups(groups));
            if (!this.hasReadWriteGroups(groups) && !this.hasReadGroups(groups)) {
              this.router.navigateByUrl("restricted");
              resolve(false);
            } else {
              resolve(true);
            }
          },
          error: (error) => {
            reject("could not get groups");
            console.error(
              " Http get request to MS Graph failed" + JSON.stringify(error)
            );
          }
        });
    });
  }

  checkContactGroupAccess(): void {
    if (this.graphUserGroups === undefined) {
      if (this.getActiveAccount()) {
        this.findReadWriteGroup(userReadWriteGroups.DLI);
      }
    }
    if (this.getActiveAccount()) {
      if (!this.hasReadWriteGroups(userReadWriteGroups.DLI)) {
        this.router.navigateByUrl("restricted");
      }
    } else {
      this.router.navigateByUrl("restricted");
    }
  }

  findReadWriteGroup(groups: string[]) {
    let userGroups: string[] = this.getActiveAccount()?.idTokenClaims?.groups as string[] || []
    this.graphUserGroups = userGroups.filter( x => groups.includes(x))
  }

  ngOnDestroy() {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
