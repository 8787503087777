import {createReducer, on} from "@ngrx/store"
import {fetchCategoriesData, updateCategoriesData,} from "./categories.action";
import {CategoryModel} from "../../models/CategoryModel";

export interface CategoriesState {
  categoriesData: CategoryModel[],
  fetchingCategoriesData: boolean,
}

export const initialState: CategoriesState = {
  categoriesData: [],
  fetchingCategoriesData: true,
}

export const categoriesReducer = createReducer(
  initialState,
  on(fetchCategoriesData, state => ({
    ...state,
    fetchingCategoriesData: true
  })),
  on(updateCategoriesData, (state, {categoriesData}) => ({
    ...state,
    categoriesData,
    fetchingCategoriesData: false
  })),
)
