export enum Page {
  home,
  trafficInfo,
  positionRequest,
  trainOnTimeAdmin,
  contacts
}

export enum PageName{
  "" = 0,
  "trafikinfo" = Page.trafficInfo,
  "positionsforfragning" = Page.positionRequest,
  "tagitidadmin" = Page.trainOnTimeAdmin,
  "kontakter" = Page.contacts,
}
