<navigation-header/>

<div class="categories-container">
  <div>
    <div>
      <filter-search-box (textChanged)="applyFilter($event)"/>
    </div>

    <div>
      <table
        mat-table
        cdkDropList
        cdkDropListLockAxis="y"
        (cdkDropListDropped)="onDrop($event)"
        [cdkDropListDisabled]="dragDisabled"
        [dataSource]="tableSource">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef>Position</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <mat-icon [ngClass]="{'move-cursor': !PhonebookTableService.isNewEntry(element.value)}"
                        (mousedown)="dragDisabled = PhonebookTableService.isNewEntry(element.value)">reorder
              </mat-icon>
              <span>{{ element.value.categoryPriorityOrder }}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Kategori</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="!editingRows.includes(element.value.categoryId)">{{ element.value.categoryName }}</span>

            <mat-form-field
              appearance="outline"
              *ngIf="editingRows.includes(element.value.categoryId)">
              <mat-label>Kategori</mat-label>
              <input matInput [formControl]="element.controls.categoryName"/>

              <mat-error *ngIf="element.controls.categoryName.hasError('required')">Kategorinamn måste anges</mat-error>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="showTo">
          <th mat-header-cell *matHeaderCellDef>Visas för</th>
          <td mat-cell *matCellDef="let element">
          <span *ngIf="!editingRows.includes(element.value.categoryId)">
            {{ mapToReadableText(element.value.group) }}
          </span>

            <div *ngIf="editingRows.includes(element.value.categoryId)">
              <mat-checkbox
                [checked]="isChecked(element.value.group, 'TrainDriver')"
                (change)="onCheckboxChange($event, element)"
                value="TrainDriver"
              >Lokförare
              </mat-checkbox>

              <mat-checkbox
                [checked]="isChecked(element.value.group,'NonTrainDriver')"
                (change)="onCheckboxChange($event, element)"
                value="NonTrainDriver"
              >Stationsvärdar
              </mat-checkbox>
              <mat-error *ngIf="element.controls.group.hasError('required') && (element.controls.group.dirty || element.controls.group.touched)">Visningsgrupp måste väljas</mat-error>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Åtgärder</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <button mat-icon-button color="primary" title="Spara"
                      *ngIf="editingRows.includes(element.value.categoryId)" (click)="save(element, 'Regular')">
                <mat-icon>save</mat-icon>
              </button>

              <button mat-icon-button title="Radera" *ngIf="editingRows.includes(element.value.categoryId)"
                      (click)="delete(element.value)">
                <mat-icon>delete_forever</mat-icon>
              </button>

              <button mat-icon-button title="Återställ" *ngIf="editingRows.includes(element.value.categoryId)"
                      (click)="reset(element.value.categoryId, element)">
                <mat-icon>undo</mat-icon>
              </button>

              <button mat-icon-button title="Redigera" *ngIf="!editingRows.includes(element.value.categoryId)"
                      (click)="edit(element.value.categoryId)">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
            cdkDrag
            [cdkDragData]="row"
            [ngClass]="{ 'has-changes': row.dirty }"
            *matRowDef="let row; columns: displayedColumns;"
        ></tr>
      </table>
    </div>

    <mat-divider/>

    <div class="footer">
      <button mat-flat-button color="primary" (click)="createNewCategoryRow()">Skapa ny kategori
        <mat-icon>category</mat-icon>
      </button>
    </div>
  </div>
</div>
