<div>
  <a mat-button routerLinkActive="active" [routerLink]="'/kontakter'">Kontakter</a>
  <a mat-button routerLinkActive="active" [routerLink]="'/kategorier'">Kategorier</a>
</div>
<div class="container">
  <div>
    <filter-box (textChanged)="applyFilter($event)" />
  </div>
  <div>
    <mat-table
      cdkDropList
      cdkDropListLockAxis="y"
      [dataSource]="formArray"
      (cdkDropListDropped)="drop($event)"
      [cdkDropListDisabled]="dragDisabled">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <ng-container matColumnDef="position" >
        <mat-header-cell *matHeaderCellDef>Position</mat-header-cell>
        <mat-cell *matCellDef="let element; let index = index">
          <mat-icon [ngClass]="{dragCursor: !element.value.categoryId.startsWith('new_')}" (mousedown)="dragDisabled = element.value.categoryId.startsWith('new_')">reorder</mat-icon>
          <span>{{ index + 1 }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Kategori</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span
            *ngIf="!editingRows.has(element.value.categoryId)">{{ element.value.categoryName }}</span>

          <mat-form-field
            appearance="outline"
            *ngIf="editingRows.has(element.value.categoryId)">
            <mat-label>Kategori</mat-label>
            <input matInput [formControl]="element.get('categoryName')"/>
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="showTo">
        <mat-header-cell *matHeaderCellDef>Visas för</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngIf="!editingRows.has(element.value.categoryId)">
            {{ mapToReadableTextArray(mapGroupFromServer(element.get('group').value)) }}
          </span>

          <div *ngIf="editingRows.has(element.value.categoryId)">
            <mat-checkbox
              [checked]="isChecked(element.get('group'), 'TrainDriver')"
              (change)="updateCheck($event, element)"
              value="TrainDriver"
            >Lokförare
            </mat-checkbox>

            <mat-checkbox
              [checked]="isChecked(element.get('group'),'NonTrainDriver')"
              (change)="updateCheck($event, element)"
              value="NonTrainDriver"
            >Stationsvärdar
            </mat-checkbox>

            <input [hidden]="true" matInput [formControl]="element.get('group')"/>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Åtgärder</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button title="Redigera" *ngIf="!editingRows.has(element.value.categoryId)" (click)="edit(element.value.categoryId)">
            <mat-icon>edit</mat-icon>
          </button>

          <button mat-icon-button title="Spara" *ngIf="editingRows.has(element.value.categoryId)" (click)="save(element)">
            <mat-icon>save</mat-icon>
          </button>

          <button mat-icon-button title="Återställ" *ngIf="editingRows.has(element.value.categoryId)" (click)="reset(element.value.categoryId, element)">
            <mat-icon>cancel</mat-icon>
          </button>

          <button mat-icon-button title="Radera" *ngIf="editingRows.has(element.value.categoryId)" (click)="delete(element.value.categoryId)">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"/>
      <mat-row
        cdkDrag
        *matRowDef="let row; columns: displayedColumns;"
        [cdkDragData]="row"
        [ngClass]="{ changes: row.dirty }"
      />
    </mat-table>
  </div>

  <mat-divider />
  <div style="align-self: flex-end; margin-top: 5px;">
    <button mat-flat-button (click)="createNewRow()">Skapa ny kategori</button>
  </div>
</div>
