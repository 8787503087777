import { Component, OnInit } from "@angular/core";
import { MessageService } from "../../services/message.service";
import { MessageModel } from "../../models/message.model";
import { UtilityService } from "../../services/utility.service";

@Component({
  selector: "app-traffic-information",
  templateUrl: "./traffic-information.component.html",
  styleUrls: ["./traffic-information.component.scss"]
})
export class TrafficInformationComponent implements OnInit {
  message: MessageModel[] = [];

  constructor(
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.messageService.clickNewMessage.subscribe(response=>{
      this.newMessage();
    });
    this.messageService.clickOpenInNewWindow.subscribe(response => {
      this.resetMessage();
    })
  }

  fetchMessage(id: string) {
    console.log("fetch message with id", id);
    if (!id) {
      console.log('hide message')
      this.resetMessage();
    } else {
      this.messageService
        .getMessageById(id)
        .subscribe({
          next: (data: any) => {
            let array = data as MessageModel[];
            array.map(x => {
              x.validFrom = new Date(x.validFrom!);
              x.validTo = UtilityService.validDate(x.validTo!)
                ? new Date(x.validTo!)
                : null;
            });
            this.message = array;
          },
          error: (error) => {
            console.error(
              "Fetch message failed: " + JSON.stringify(error)
            );
          }
        })
    }
  }

  newMessage(){
    this.message = [new MessageModel()];
  }
  resetMessage() {
    this.message = [];
  }
}
