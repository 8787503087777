// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  development: true,
  test: false,
  url: "https://dev-amigo.stockholmstag.sj.se",
  loginRedirect: "https://dev-amigo.stockholmstag.sj.se/signin-oidc",
  messagesBaseUrl: "https://dev-api.stockholmstag.sj.se/public/messagesapi",
  contactApiBaseUrl: "https://dev-api.stockholmstag.sj.se/public/contactapi",
  graphUrl: "https://graph.microsoft.com/v1.0",
  getMessagesApiSuffix: "/api/Message/GetMessages",
  getToTMessagesApiSuffix: "/api/Message/GetToTMessages",
  createMessageApiSuffix: "/api/Message/CreateMessage",
  updateMessageApiSuffix: "/api/Message/UpdateMessage",
  getMessagesByIdApiSuffix: "/api/Message/GetMessagesById",
  messagesScopes: "api://cddfa479-5a1c-4c43-91fa-aaa4bd9ffbf0/access_as_user",
  contactScopes: "api://79323930-fd18-449f-bf90-f2a47ed8d028/access_as_user",
  clientID: "c328157b-2286-4741-ad9d-21471679c3b2",
  tenantID: "2146c4ae-6471-40a8-b821-2774c8057963",
  loginUrl: "https://login.microsoftonline.com/",
  trainOnTimeAdminUrl: "https://app-sjp-tot-dev.azurewebsites.net/admin",
  readWriteGroups: ["9ec4358d-6488-409a-97b4-3f43a6505aba"]
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
