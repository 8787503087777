import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MessageFilter } from "../models/message-filter.model";
import { environment } from "../../environments/environment";
import { MessageModel } from "../models/message.model";
import { interval, EMPTY } from "rxjs";
import { startWith, switchMap } from "rxjs/operators";
import { MessageOverview } from "../models/message-overview.model";
import { UtilityService } from "./utility.service";


@Injectable({
  providedIn: "root"
})
export class MessageService {
  @Output() updateList: EventEmitter<string> = new EventEmitter();
  @Output() clickNewMessage: EventEmitter<string> = new EventEmitter(); //emit null
  @Output() clickOpenInNewWindow: EventEmitter<string> = new EventEmitter(); //clear main page message

  errors: Map<string, any> = new Map();
  showErrors: boolean = false;
  loading: boolean = false;
  filter: MessageFilter | null = null;
  latestUpdate: Date | null = null;
  messageLength: number = 0;
  messageChange: EventEmitter<MessageOverview[]> = new EventEmitter();
  useAsTemplate: EventEmitter<string> = new EventEmitter();
  pollingActive: boolean = false;
  pollingInterval: number = 60000;

  currentMessage: MessageModel | null = null;
  originalMessage: MessageModel | null = null;

  /* Specs for when opening a message in a new window */
  windowHeight: number = 600; //px
  windowWidth: number = 600; //px
  windowLocation: string = "yes"; // yes | no (display address bar)
  windowScrollbars: string = "yes"; //  yes | no (display scroll bars)
  windowStatus: string = "yes"; //  yes | no (display scroll bars)
  requestHeader: HttpHeaders = new HttpHeaders({ "api-version": "1.0", "app-version": "1.0" });

  constructor(private httpClient: HttpClient) { }

  getAllMessagesAsync(filter?: MessageFilter) {
    if (filter) this.filter = filter;
    if (!this.filter) this.filter = new MessageFilter();

    //const clone = new MessageFilter();
    //Object.assign(clone, this.filter);
    //if (clone.to !== null && clone.to.getTimezoneOffset() !== 0) {
    //  const copiedDate = new Date(clone.to.getTime());
    //  copiedDate.setUTCDate(clone.to.getDate());
    //  clone.to = copiedDate;
    //}

    return this.httpClient.post(
      environment.messagesBaseUrl + environment.getMessagesApiSuffix,
      UtilityService.setMessageApiFilter(this.filter),
      { headers: this.requestHeader }
    );
  }

  pollMessageListHandler(res: any){
    if (res && res.length > 0)
      res.sort((a, b) => (a.lastUpdatedAt > b.lastUpdatedAt ? -1 : 1));

    this.messageLength = res.length;
    this.messageChange.emit(res);
  }

  handleError(error: any){
    console.error(error);
  }
  pollMessageList() {
    if (!this.pollingActive) {
      this.pollingActive = true;
    }
    interval(this.pollingInterval)
      .pipe(
        startWith(0),
        switchMap(() => this.getAllMessagesAsync())
      )
      .subscribe({
        next: this.pollMessageListHandler.bind(this),
        error: this.handleError.bind(this)
      });
  }

  getMessageById(id: string) {
    if (id) {
      return this.httpClient.get(
        environment.messagesBaseUrl + environment.getMessagesByIdApiSuffix + `/${id}`,
        { headers: this.requestHeader }
      );
    } else {
      return EMPTY;
    }
  }

  createMessage(message: MessageModel) {
    return this.httpClient.post(
      environment.messagesBaseUrl + environment.createMessageApiSuffix,
      UtilityService.setMessageApiModel(message),
      { headers: this.requestHeader }
    );
  }

  updateMessage(message: MessageModel) {
    return this.httpClient.post(
      environment.messagesBaseUrl + environment.updateMessageApiSuffix,
      UtilityService.setMessageApiModel(message),
      { headers: this.requestHeader }
    );
  }

  setError(property, value) {
    if (this.errors.has(property) && value == null) {
      this.errors.delete(property);
    } else if (value != null) {
      this.errors.set(property, value);
    }
  }

  getError(property) {
    if (this.errors.has(property)) return this.errors.get(property);
  }

  hasChanges() {
    return (
      !this.loading &&
      !UtilityService.equals(this.originalMessage, this.currentMessage)
    );
  }

  getNewMessageID() {
    return "new_message" + new Date().valueOf();
  }

  getNewMessageWindowSpecs() {
    let specsArray = [
      "location=" + this.windowLocation,
      "height=" + this.windowHeight,
      "width=" + this.windowWidth,
      "scrollbars=" + this.windowScrollbars,
      "status=" + this.windowStatus
    ];
    return specsArray.join(',');
  }
}
