import {Component, importProvidersFrom, OnInit} from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { environment } from "../../../environments/environment";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { classicUrl } from "../../../assets/classic-url";
import {MatIcon, MatIconModule} from "@angular/material/icon";
import {MsalModule} from "@azure/msal-angular";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  standalone: true,
  imports: [
    MatIconModule
  ],
  providers: [
  ],
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  messageTest: string = '';
  htmlError: string = '';
  url: SafeResourceUrl | undefined;
  constructor(
    private authService: AuthService, private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.navigateToWebsite();
  }

  getUser() {
    return "User info:\n" + this.authService.getActiveAccount();
  }

  goToToT() {
    if (environment.development) {
      window.open(classicUrl.Dev, "_blank")
    }
    else if (environment.test) {
      window.open(classicUrl.Test, "_blank")
    }
    else if (environment.production) {
      window.open(classicUrl.Prod, "_blank")
    }
    else {
      window.open(classicUrl.Dev, "_blank")
    }
  }

  navigateToWebsite() {
    if (environment.development) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(classicUrl.Dev);
    }
    else if (environment.test) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(classicUrl.Test);
    }
    else if (environment.production) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(classicUrl.Prod);
    }
    else {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(classicUrl.Dev);
    }
  }
}
