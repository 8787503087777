<div [ngClass] ="getMessageContainer()" *ngIf="message">
  <app-header
    [message]="originalMessage"
    [numberOfVersions]="numberOfVersions"
    [laterMessageExists]="laterMessageExists"
    (changeVersion)="changeVersion($event)"
    (saveChanges)="saveChanges($event)"
    (closeMessage)="closeMessage($event)"
  ></app-header>
  <div class="message-body" [ngClass] ="{'new-message': !currentMessage?.id, 'new-window' : getNewWindowClass()}">
    <div style="display: flow-root;">
      <div
        class="checkbox-group"
        style="margin-bottom: 6px;"
        *ngIf="isNewMessage"
      >
        <span style="margin-right:10px;"> Skicka till </span>
        <button
          class="transparent-button-light-big checkbox"
          (click)="onChangeMessageType(true)"
        >
          <mat-icon class="button-checkbox" *ngIf="showApp"
          >radio_button_checked</mat-icon
          >
          <mat-icon class="button-checkbox" *ngIf="!showApp"
          >radio_button_unchecked</mat-icon
          >
          <span class="button-checkbox">Appen</span>
        </button>
        <button
          class="transparent-button-light-big checkbox"
          (click)="onChangeMessageType(false)"
        >
          <mat-icon class="button-checkbox" *ngIf="!showApp"
          >radio_button_checked</mat-icon
          >
          <mat-icon class="button-checkbox" *ngIf="showApp"
          >radio_button_unchecked</mat-icon
          >
          <span class="button-checkbox">Tåg i Tid</span>
        </button>
      </div>
      <div
        class="checkbox-group"
        style="margin-bottom: 6px;"
        *ngIf="!isNewMessage"
      >
        Skickas till: {{ showApp ? "Appen" : "Tåg i Tid" }}
      </div>
      <!-- <div
        class="checkbox-group"
        style="margin-bottom: 6px;float:right;"
        *ngIf="!isReadOnly()"
      >
        <button
          mat-icon-button
          class="transparent-button-light-big checkbox"
          (click)="currentMessage.sendMail = !currentMessage.sendMail"
        >
          <mat-icon class="button-checkbox" *ngIf="currentMessage.sendMail"
            >check_box</mat-icon
          >
          <mat-icon class="button-checkbox" *ngIf="!currentMessage.sendMail"
            >check_box_outline_blank</mat-icon
          >
          <span class="button-checkbox">Skicka till SL Kundtjänst</span>
        </button>
      </div>
      <div
        class="checkbox-group"
        style="margin-bottom: 6px;float:right;"
        *ngIf="isReadOnly() && currentMessage?.sendMail"
      >
        Skickad till SL Kundtjänst
      </div> -->
    </div>

    <app-app-message-form
      *ngIf="messageType == 0"
      [message]="currentMessage!"
      [numberOfVersions]="numberOfVersions"
    />
    <app-web-message-form
      *ngIf="messageType == 1"
      [message]="currentMessage!"
      [numberOfVersions]="numberOfVersions"
    />
  </div>
</div>
<div class="text-container" *ngIf="!message">
  <div class="centered-text">Inget meddelande valt</div>
</div>
