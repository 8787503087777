export class ContactApiModel {
  constructor(
    public id: string | null = null,
    public name: string | null = null,
    public description: string | null = null,
    public phoneNumber: string | null = null,
    public phoneNumberNote: string | null = null,
    public categoryId: string | null = null,
    public visible: boolean = true,
    public priorityOrder: number,
  ) {}
}
