import {createReducer, on} from "@ngrx/store"
import {
  addEditingRowsData,
  fetchCategoriesData,
  removeEditingRowsData, resetEditingRowsData,
  updateCategoriesData,
} from "./categories.action";
import {CategoryModel} from "../../models/CategoryModel";

export interface CategoriesState {
  fetchingCategoriesData: boolean,
  categoriesData: CategoryModel[],
  editingRowsData: string[],
}

export const initialState: CategoriesState = {
  fetchingCategoriesData: true,
  categoriesData: [],
  editingRowsData: [],
}

export const categoriesReducer = createReducer(
  initialState,
  on(fetchCategoriesData, state => ({
    ...state,
    fetchingCategoriesData: true
  })),
  on(updateCategoriesData, (state, { categoriesData }) => ({
    ...state,
    categoriesData,
    fetchingCategoriesData: false
  })),
  on(addEditingRowsData, (state, { rowId }) => {
    const uniqueSet = new Set(state.editingRowsData)
      .add(rowId);

    return ({
      ...state,
      editingRowsData: Array.from(uniqueSet),
    });
  }),
  on(removeEditingRowsData, (state, { rowId }) => {
    const uniqueSet = new Set(state.editingRowsData)
    uniqueSet.delete(rowId);

    return ({
      ...state,
      editingRowsData: Array.from(uniqueSet),
    });
  }),
  on(resetEditingRowsData, (state) => ({
    ...state,
    editingRowsData: [],
  })),
)
