import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  openDeleteConfirmation(name: string): boolean {
    return window.confirm(`Är du säker du vill radera "${name}"`)
  }

  unsavedNavigationConfirmation(): Observable<boolean> {
    const confirmation = window.confirm('Du har osparade ändringar.\nÄr du villig att förlora förändringarna?');
    return of(confirmation);
  }
}
