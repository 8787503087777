<div class="background">
  <button class="btn overlay-button" (click)="goToToT()">
    <span style="vertical-align: text-bottom;">Till Tåg i Tid </span><mat-icon class="forward-icon">arrow_forward</mat-icon>
  </button>
  <iframe
    class="embedded-website"
    [src]="url"
    style="border: 0"
  ></iframe>
</div>
