<form [formGroup]="messageForm">
  <div class="split left">
    <label class="blocking">
      Visas från
      <span *ngIf="showErrors('validFrom')" class="error">
        {{ errorMessage("validFrom") }}
      </span>
    </label>
    <div class="input-container">
      <p-datePicker
        (onSelect)="onDateChange($event, 'validFrom')"
        (onClearClick)="onDateChange(null, 'validFrom')"
        (onBlur)="onDateInputBlur($event, 'validFrom')"
        formControlName="validFrom"
        dateFormat="yy-mm-dd"
        hourFormat="24"
        placeholder="Nu"
        appendTo="body"
        showTransitionOptions="0ms ease-out"
        hideTransitionOptions="0ms ease-in"
        styleClass="calendar-input"
        [disabled]="isReadOnly()"
        [selectOtherMonths]="true"
        [showTime]="true"
        [showIcon]="true"
        [showButtonBar]="true"  
      />
    </div>

  </div>

  <div class="split right">
    <label class="blocking">
      Visas till
      <span *ngIf="showErrors('validTo')" class="error">
        {{ errorMessage("validTo") }}
      </span>
    </label>
    <div class="input-container">
      <p-datePicker
        (onSelect)="onDateChange($event, 'validTo')"
        (onClearClick)="onDateChange(null, 'validTo')"
        (onBlur)="onDateInputBlur($event, 'validTo')"
        formControlName="validTo"
        dateFormat="yy-mm-dd"
        hourFormat="24"
        placeholder="Tills vidare"
        appendTo="body"
        showTransitionOptions="0ms ease-out"
        hideTransitionOptions="0ms ease-in"
        styleClass="calendar-input"
        [disabled]="isReadOnly()"
        [selectOtherMonths]="true"
        [showTime]="true"
        [showIcon]="true"
        [showButtonBar]="true"
      />
    </div>
  </div>
  <label class="inline-block">
    Rubrik<span *ngIf="!isReadOnly()">*</span>
    <span *ngIf="showErrors('heading')" class="error">
      {{ errorMessage("heading") }}
    </span>
    <span class="count-name" *ngIf="!isReadOnly()">{{ getCount("heading") }}/{{ maxLength.heading }}</span>
  </label>
  <textarea
  [ngClass]="{ 'error-input': showErrors('heading') }"
  *ngIf="!isReadOnly()"
  contenteditable="true"
  class="expanding-textarea"
  id="headingDiv"
  [value]="this.messageForm.get('heading')!.value || ''"
  [maxLength]="maxLength.heading"
  (paste)="onTextboxPaste($event, 'heading', maxLength.heading, true)"
  (keyup)="onTextboxChange($event, 'heading', maxLength.heading, true)"
  (keydown)="limitUserInput($event, maxLength.heading)"
></textarea>

  <textarea *ngIf="isReadOnly()"
       disabled
       contenteditable="false"
       [value]="this.messageForm.get('heading')!.value || ''"
       class="expanding-textarea disabled"
       id="headingDiv2"></textarea>
  <div class="blocking">
    <div class="split left">
      <label class="blocking">
        Plats
        <span *ngIf="showErrors('location')" class="error">
          {{ errorMessage("location") }}
        </span><span class="count" *ngIf="!isReadOnly()">{{ getCount("location") }}/{{ maxLength.location }}</span>
      </label>
      <textarea [ngClass]="{ 'error-input': showErrors('location') }"
           *ngIf="!isReadOnly()"
           contenteditable="true"
           class="expanding-textarea"
           id="locationDiv"
           [value]="this.messageForm.get('location')!.value || ''"
           [maxLength]="maxLength.location"
           (paste)="onTextboxPaste($event, 'location', maxLength.location, false)"
           (keyup)="onTextboxChange($event, 'location', maxLength.location, false)"
           (keydown)="limitUserInput($event, maxLength.location)"
      ></textarea>
      <textarea *ngIf="isReadOnly()"
           disabled
           contenteditable="false"
           [value]="this.messageForm.get('location')!.value || ''"
           class="expanding-textarea disabled"
           id="locationDiv2"></textarea>
    </div>
    <div class="split right">
      <label class="blocking">
        Berörda
        <span *ngIf="showErrors('affects')" class="error">
          {{ errorMessage("affects") }}
        </span><span class="count" *ngIf="!isReadOnly()">{{ getCount("affects") }}/{{ maxLength.affects }}</span>
      </label>
      <textarea [ngClass]="{ 'error-input': showErrors('affects') }"
           *ngIf="!isReadOnly()"
           contenteditable="true"
           class="expanding-textarea"
           id="affectsDiv"
           [value]="this.messageForm.get('affects')!.value || ''"
           [maxLength]="maxLength.affects"
           (paste)="onTextboxPaste($event, 'affects', maxLength.affects, true)"
           (keyup)="onTextboxChange($event, 'affects', maxLength.affects, false)"
           (keydown)="limitUserInput($event, maxLength.affects)"
      ></textarea>
      <textarea *ngIf="isReadOnly()"
           disabled
           contenteditable="false"
           [value]="this.messageForm.get('affects')!.value || ''"
           class="expanding-textarea disabled"
           id="affectsDiv2"></textarea>
    </div>    
    <div class="split left">
      <label class="blocking">Mottagare: </label>
      <div class="checkbox-group">
        <button [disabled]="isReadOnly()"
                class="transparent-button-light-big checkbox"
                (click)="toggleAllPSelection()">
          <mat-icon class="button-checkbox" *ngIf="allPSelected">check_box</mat-icon>
          <mat-icon class="button-checkbox" *ngIf="!allPSelected">check_box_outline_blank</mat-icon>
          <span class="button-checkbox">Alla</span>
        </button>
        <button *ngFor="let notifyGroup of notifyGroupsList"
              class="transparent-button-light-big checkbox"
              [disabled]="isReadOnly()"
              (click)="togglePerOne(notifyGroup)">
          <mat-icon class="button-checkbox" *ngIf="notifyGroup.selected">check_box</mat-icon>
          <mat-icon class="button-checkbox" *ngIf="!notifyGroup.selected">check_box_outline_blank</mat-icon>
          <span class="button-checkbox">{{ notifyGroup.displayName }}</span>
        </button>
        <button class="transparent-button-light-big checkbox"
        [disabled]="isReadOnly()"
        (click)="toggleVarious()">
    <mat-icon class="button-checkbox" *ngIf="variousSelected">check_box</mat-icon>
    <mat-icon class="button-checkbox" *ngIf="!variousSelected">check_box_outline_blank</mat-icon>
    <span class="button-checkbox">Övrigt</span>
</button>
      </div>
    </div>
  </div>

  <div>
    <label class="blocking">Tyst notis</label>
    <div class="checkbox-group">
      <button [disabled]="isReadOnly()"
              class="transparent-button-light-big checkbox"
              (click)="onToggle('silentMtrp')">
        <mat-icon class="button-checkbox" *ngIf="silentMtrp">check_box</mat-icon>
        <mat-icon class="button-checkbox" *ngIf="!silentMtrp">check_box_outline_blank</mat-icon>
        <span class="button-checkbox">Pendeln</span>
      </button>
    </div>
  </div>

  <label class="blocking">
    Händelse
    <span class="count" *ngIf="!isReadOnly()">{{ getCount("incident") }}/{{ maxLength.incident }}</span>
  </label>
  <div *ngIf="showErrors('incident')" class="error">
    {{ errorMessage("incident") }}
  </div>
  <textarea [ngClass]="{ 'error-input': showErrors('incident') }"
       *ngIf="!isReadOnly()"
       contenteditable="true"
       class="expanding-textarea"
       id="incidentDiv"
       [value]="this.messageForm.get('incident')!.value || ''"
       [maxLength]="maxLength.incident"
       (paste)="onTextboxPaste($event, 'incident', maxLength.incident, false)"
       (keyup)="onTextboxChange($event, 'incident', maxLength.incident, false)"
       (keydown)="limitUserInput($event, maxLength.incident)"
  ></textarea>
  <textarea *ngIf="isReadOnly()"
       disabled
       contenteditable="false"
       [value]="this.messageForm.get('incident')!.value || ''"
       class="expanding-textarea disabled"
       id="incidentDiv2"></textarea>
  <label class="blocking">
    Åtgärd
    <span *ngIf="showErrors('action')" class="error">
      {{ errorMessage("action") }}
    </span><span class="count" *ngIf="!isReadOnly()">{{ getCount("action") }}/{{ maxLength.action }}</span>
  </label>
  <textarea [ngClass]="{ 'error-input': showErrors('action') }"
       *ngIf="!isReadOnly()"
       contenteditable="true"
       class="expanding-textarea"
       id="actionDiv"
       [value]="this.messageForm.get('action')!.value || ''"
       [maxLength]="maxLength.action"
       (paste)="onTextboxPaste($event, 'action', maxLength.action, false)"
       (keyup)="onTextboxChange($event, 'action', maxLength.action, false)"
       (keydown)="limitUserInput($event, maxLength.action)"
  ></textarea>
  <textarea *ngIf="isReadOnly()"
       disabled
       contenteditable="false"
       [value]="this.messageForm.get('action')!.value || ''"
       class="expanding-textarea disabled"
       id="actionDiv2"></textarea>
  <label class="blocking">
    Trafikinformation
    <span *ngIf="showErrors('trafficInfo')" class="error">
      {{ errorMessage("trafficInfo") }}
    </span><span class="count" *ngIf="!isReadOnly()">{{ getCount("trafficInfo") }}/{{ maxLength.trafficInfo }}</span>
  </label>
  <textarea [ngClass]="{ 'error-input': showErrors('trafficInfo') }"
       *ngIf="!isReadOnly()"
       contenteditable="true"
       class="expanding-textarea"
       id="trafficInfoDiv"
       [value]="this.messageForm.get('trafficInfo')!.value || ''"
       [maxLength]="maxLength.trafficInfo"
       (paste)="onTextboxPaste($event, 'trafficInfo', maxLength.trafficInfo, false)"
       (keyup)="onTextboxChange($event, 'trafficInfo', maxLength.trafficInfo, false)"
       (keydown)="limitUserInput($event, maxLength.trafficInfo)"
  ></textarea>
  <textarea *ngIf="isReadOnly()"
       disabled
       contenteditable="false"
       [value]="this.messageForm.get('trafficInfo')!.value || ''"
       class="expanding-textarea disabled"
       id="trafficInfoDiv2"></textarea>
  <label class="blocking">
    Prognos
    <span *ngIf="showErrors('estimation')" class="error">
      {{ errorMessage("estimation") }}
    </span><span class="count" *ngIf="!isReadOnly()">{{ getCount("estimation") }}/{{ maxLength.estimation }}</span>
  </label>
  <textarea [ngClass]="{ 'error-input': showErrors('estimation') }"
       *ngIf="!isReadOnly()"
       contenteditable="true"
       class="expanding-textarea"
       id="estimationDiv"
       [value]="this.messageForm.get('estimation')!.value || ''"
       [maxLength]="maxLength.estimation"
       (paste)="onTextboxPaste($event, 'estimation', maxLength.estimation, false)"
       (keyup)="onTextboxChange($event, 'estimation', maxLength.estimation, false)"
       (keydown)="limitUserInput($event, maxLength.estimation)"
  ></textarea>
  <textarea *ngIf="isReadOnly()"
       disabled
       contenteditable="false"
       [value]="this.messageForm.get('estimation')!.value || ''"
       class="expanding-textarea disabled"
       id="estimationDiv2"></textarea>
  <label class="blocking">
    Alternativa resvägar<span *ngIf="showErrors('alternativeRoute')" class="error">{{
      errorMessage("alternativeRoute")
    }}</span><span class="count" *ngIf="!isReadOnly()">{{ getCount("alternativeRoute") }}/{{ maxLength.alternativeRoute }}</span>
  </label>
  <textarea [ngClass]="{ 'error-input': showErrors('alternativeRoute') }"
       *ngIf="!isReadOnly()"
       contenteditable="true"
       class="expanding-textarea"
       id="alternativeRouteDiv"
       [value]="this.messageForm.get('alternativeRoute')!.value || ''"
       [maxLength]="maxLength.alternativeRoute"
       (paste)="onTextboxPaste($event, 'alternativeRoute', maxLength.alternativeRoute, false)"
       (keyup)="onTextboxChange($event, 'alternativeRoute', maxLength.alternativeRoute, false)"
       (keydown)="limitUserInput($event, maxLength.alternativeRoute)"
  ></textarea>
  <textarea *ngIf="isReadOnly()"
       contenteditable="true"
       [value]="this.messageForm.get('alternativeRoute')!.value || ''"
       class="expanding-textarea"
       id="alternativeRouteDiv2"></textarea>
  <div class="blocking">
    <div class="checkbox-group">
      <button [disabled]="isReadOnly()"
              class="transparent-button-light-big checkbox"
              (click)="onToggle('ticketCooperation')">
        <mat-icon class="button-checkbox" *ngIf="ticketCooperation">check_box</mat-icon>
        <mat-icon class="button-checkbox" *ngIf="!ticketCooperation">check_box_outline_blank</mat-icon>
        <span class="button-checkbox">Biljettsamverkan</span>
      </button>
      <button [disabled]="isReadOnly()"
              class="transparent-button-light-big checkbox"
              (click)="onToggle('replacementBusOrdered')">
        <mat-icon class="button-checkbox" *ngIf="replacementBusOrdered">check_box</mat-icon>
        <mat-icon class="button-checkbox" *ngIf="!replacementBusOrdered">check_box_outline_blank</mat-icon>
        <span class="button-checkbox">Ersättningsbussar beställda</span>
      </button>
      <button [disabled]="isReadOnly()"
              class="transparent-button-light-big checkbox"
              (click)="onToggle('replacementBusConfirmed')">
        <mat-icon class="button-checkbox" *ngIf="replacementBusConfirmed">check_box</mat-icon>
        <mat-icon class="button-checkbox" *ngIf="!replacementBusConfirmed">check_box_outline_blank</mat-icon>
        <span class="button-checkbox">Ersättningsbussar bekräftade</span>
      </button>
    </div>
  </div>

  <label class="blocking">
    Kommentar
    <span *ngIf="showErrors('body')" class="error">
      {{ errorMessage("body") }}
    </span><span class="count" *ngIf="!isReadOnly()">{{ getCount("body") }}/{{ maxLength.body }}</span>
  </label>
  <textarea [ngClass]="{ 'error-input': showErrors('body') }"
       *ngIf="!isReadOnly()"
       contenteditable="true"
       class="expanding-textarea"
       id="bodyDiv"
       [value]="this.messageForm.get('body')!.value || ''"
       [maxLength]="maxLength.body"
       (paste)="onTextboxPaste($event, 'body', maxLength.body, false)"
       (keyup)="onTextboxChange($event, 'body', maxLength.body, false)"
       (keydown)="limitUserInput($event, maxLength.body)"
  ></textarea>
  <textarea *ngIf="isReadOnly()"
       disabled
       contenteditable="false"
       [value]="this.messageForm.get('body')!.value || ''"
       class="expanding-textarea disabled"
       id="bodyDiv2"></textarea>
</form>
