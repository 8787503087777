import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, EMPTY, map, switchMap} from "rxjs";
import {
  createCategoryData,
  deleteCategoryData,
  fetchCategoriesData,
  updateCategoriesData,
  updateCategoryData,
} from "./categories.action";
import {ContactApiService} from "../../services/contact-api.service";
import {SnackbarService} from "../../services/snackbar.service";

@Injectable()
export class CategoriesEffects {
  constructor(private actions$: Actions, private contactsAPI: ContactApiService, private snackbarService: SnackbarService ) {}

  fetchCategoriesData$ = createEffect(() => this.actions$.pipe(
    ofType(fetchCategoriesData),
    switchMap(() =>
      this.contactsAPI.fetchCategoriesData().pipe(
          map((categoriesData) => updateCategoriesData({ categoriesData })),
          catchError(() => EMPTY)
        ))
    ))

  createCategoryData$ = createEffect(() => this.actions$.pipe(
    ofType(createCategoryData),
    switchMap((props =>
        this.contactsAPI.createCategoryData(props.category).pipe(
          map(fetchCategoriesData),
          catchError(() => {
            this.snackbarService.showError("Misslyckades skapa kategori");
            return EMPTY;
          })
        )
    ))))

  updateCategoryData$ = createEffect(() => this.actions$.pipe(
    ofType(updateCategoryData),
    switchMap((props =>
      this.contactsAPI.updateCategoryData(props.category).pipe(
          map(fetchCategoriesData),
          catchError(() => {
            this.snackbarService.showError("Misslyckades ändra kategori");
            return EMPTY;
          })
        ))
    )))

  deleteCategoryData$ = createEffect(() => this.actions$.pipe(
    ofType(deleteCategoryData),
    switchMap((props =>
      this.contactsAPI.deleteCategoryData(props.categoryId).pipe(
          map(fetchCategoriesData),
          catchError(() => {
            this.snackbarService.showError("Misslyckades radera kategori");
            return EMPTY;
          })
        )
    ))))
}
