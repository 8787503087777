import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "userInfoFilter" })
export class UserInfoFilterPipe implements PipeTransform {
  transform(nameAndEmail: string) {
    if (nameAndEmail == null) {
      return null;
    } else {
      const position = nameAndEmail.indexOf(" (");
      if (position === -1) {
        return nameAndEmail;
      }
      return nameAndEmail.substring(0, position);
    }
  }
}
