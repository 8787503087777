import {Component, HostListener, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {CategoryModel, Group} from "../../../models/CategoryModel";
import {Store} from "@ngrx/store";
import {selectCategoriesData, selectEditingRowsData} from "../../../store/categories/categories.selector";
import {Observable} from "rxjs";
import {
  addEditingRowsData,
  createCategoryData,
  deleteCategoryData,
  fetchCategoriesData,
  removeEditingRowsData,
  resetEditingRowsData,
  updateCategoryData
} from "../../../store/categories/categories.action";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {MatTable, MatTableDataSource, MatTableModule} from "@angular/material/table";
import {CdkDrag, CdkDragDrop, CdkDropList} from "@angular/cdk/drag-drop";
import {CategoryApiModel} from "../../../models/api-category.model";
import {MatCheckboxChange, MatCheckboxModule} from "@angular/material/checkbox";
import {PhonebookService} from "../../../services/phonebook.service";
import {AuthService} from "../../../services/auth.service";
import {userReadWriteGroups} from "../../../../assets/user-groups";
import {SaveMethod} from "../../../types/contacts-types";
import {MatInputModule} from "@angular/material/input";
import {MatListModule} from "@angular/material/list";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatIconModule} from "@angular/material/icon";
import {NgClass, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {Router} from "@angular/router";
import {FilterSearchBoxComponent} from "../shared/filter-search-box/filter-search-box.component";
import {ThemePalette} from "@angular/material/core";
import {NavigationHeaderComponent} from "../shared/navigation-header/navigation-header.component";
import {DialogService} from "../../../services/dialog.service";

export type CategoryFormGroup = FormGroup<{
  categoryId: FormControl<string>;
  categoryName: FormControl<string>;
  group: FormControl<Group>;
  categoryPriorityOrder: FormControl;
}>;

@Component({
  selector: "categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.scss", "../shared/shared.scss"],
  standalone: true,
  imports: [
    CdkDrag,
    CdkDropList,
    MatCheckboxModule,
    MatInputModule,
    MatTableModule,
    MatListModule,
    MatSnackBarModule,
    MatIconModule,
    NgClass,
    ReactiveFormsModule,
    MatButtonModule,
    NgIf,
    FilterSearchBoxComponent,
    NavigationHeaderComponent,
  ]
})
export class CategoriesComponent implements OnInit, OnDestroy {
  protected readonly PhonebookTableService = PhonebookService;
  @ViewChild(MatTable) table!: MatTable<CategoryModel>;
  editingRowsData$: Observable<string[]>;
  editingRows: string[] = [];
  categoriesData$: Observable<CategoryModel[]>;
  dragDisabled = true;
  displayedColumns: string[] = [
    "position",
    "name",
    "showTo",
    "actions",
  ];
  tableSource: MatTableDataSource<CategoryFormGroup> = new MatTableDataSource();
  tableItems!: FormArray<CategoryFormGroup>;
  color: ThemePalette;

  constructor(
    public router: Router,
    private authService: AuthService,
    private store: Store,
    private fb: FormBuilder,
    private dialogService: DialogService
  ) {
    this.categoriesData$ = this.store.select(selectCategoriesData);
    this.editingRowsData$ = this.store.select(selectEditingRowsData);
  }

  async ngOnInit(): Promise<void> {
    await this.authService.hasGroupsAsync(userReadWriteGroups.DLI);
    this.authService.checkContactGroupAccess();

    this.store.dispatch(fetchCategoriesData());

    this.editingRowsData$.subscribe(editingRows => {
      this.editingRows = editingRows;
    });
    this.categoriesData$.subscribe(categoriesData => {
      const unsavedNewRowIds = this.tableItems?.value.filter(item => this.editingRows.includes(item.categoryId!) && item.categoryId?.startsWith('new_')) || [];
      const unsavedNewRowGroups = unsavedNewRowIds.map(existingNewUnsavedCategory => {
          // Keep any existing new row values from client (except always nullify categoryPriorityOrder because new row)
          const editingRow = this.generateContactWithValidation({
            categoryId: existingNewUnsavedCategory.categoryId!,
            categoryName: existingNewUnsavedCategory.categoryName!,
            group: existingNewUnsavedCategory.group!,
            categoryPriorityOrder: null,
          });
          editingRow.markAsDirty();
          return editingRow;
      });

      this.tableItems = new FormArray([...categoriesData]
          .sort((a, b) => PhonebookService.compare(a.categoryPriorityOrder!, b.categoryPriorityOrder!, true))
          .map((category) => {
            const isEditedRow = this.editingRows.includes(category.categoryId);
            const existingEditedCategory = isEditedRow && this.tableItems?.value.find(item => item.categoryId === category.categoryId);

            if (existingEditedCategory) {
              // Keep any existing edited values from client (except always take priorityOrder from server!)
              const editingRow = this.generateContactWithValidation({
                categoryId: existingEditedCategory.categoryId!,
                categoryName: existingEditedCategory.categoryName!,
                group: existingEditedCategory.group!,
                categoryPriorityOrder: category.categoryPriorityOrder,
              });
              editingRow.markAsDirty();
              return editingRow;
            }

            // Else generate new fresh rows based on server data
            return this.generateContactWithValidation({
              categoryId: category.categoryId,
              categoryName: category.categoryName,
              group: category.group,
              categoryPriorityOrder: category.categoryPriorityOrder,
            });
          }).concat([...unsavedNewRowGroups]));

      this.tableSource.data = this.tableItems.controls;
      this.tableSource.filterPredicate = (data: FormGroup, filter: string) => {
        const searchableKeys = ['categoryName'];
        return PhonebookService.filterPredicate(data, filter, searchableKeys);
      };
    });
  }

  /**
   * Before exiting site (reload), checks if table has active changes and gives warning.
   * https://developer.mozilla.org/en-US/docs/Web/API/BeforeUnloadEvent
   */
  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHandler(event: BeforeUnloadEvent) {
    event.stopPropagation();

    return !this.tableItems.dirty;
  }

  /**
   * Used to show confirmation when navigating via nav buttons and user has changes/dirty table
   * https://developer.mozilla.org/en-US/docs/Web/API/BeforeUnloadEvent
   */
  canDeactivate(): Observable<boolean> | boolean {
    if (!this.tableItems.dirty) {
      return true;
    }

    return this.dialogService.unsavedNavigationConfirmation();
  }

  onCheckboxChange(checkbox: MatCheckboxChange, element: AbstractControl) {
    const rowGroup = element.get('group')!;
    const value = this.groupArrayToSingleGroup([rowGroup.value, checkbox.source.value]);
    if (checkbox.checked) {
      rowGroup.setValue(value);
    } else {
      const arrayOfGroups = new Set(this.groupToArray(rowGroup.value));
      arrayOfGroups.delete(checkbox.source.value as Group);
      const groupsWithoutUnchecked = Array.from(arrayOfGroups);

      rowGroup.setValue(this.groupArrayToSingleGroup(groupsWithoutUnchecked));
    }
    rowGroup.markAsDirty();
  }

  onDrop(event: CdkDragDrop<FormGroup<{
    categoryId: FormControl<string>;
    categoryName: FormControl<string>;
    categoryPriorityOrder: FormControl<number>;
    group: FormArray<FormControl<CategoryModel["group"]>>
  }>, any>) {
    // Return the drag container to be disabled.
    this.dragDisabled = true;
    const { isNew } = PhonebookService.onDrop(this.tableSource, "categoryPriorityOrder", event);
    if (!isNew) {
      this.save(event.item.data, "Drop");
    }
  }

  reset(id: string, element?: AbstractControl) {
    this.store.dispatch(removeEditingRowsData({ rowId: id }));
    PhonebookService.resetRow(this.tableItems, element!, id);
    this.table.renderRows();
  }

  edit(id: string) {
    this.store.dispatch(addEditingRowsData({ rowId: id }));
  }

  delete(rowElement: CategoryModel) {
    if (PhonebookService.isNewEntry(rowElement)) {
      // Remove from table array if new row, skip showing confirmation dialog
      const newId = this.tableItems.getRawValue().findIndex(item => item.categoryId.includes(rowElement.categoryId));
      if (newId >= 0) {
        this.tableItems.removeAt(newId);
        this.table.renderRows();
      }
    } else if (this.dialogService.openDeleteConfirmation(rowElement.categoryName)) {
      this.store.dispatch(deleteCategoryData({ categoryId: rowElement.categoryId }));
    }
  }

  save(rowElement: AbstractControl<CategoryModel>, saveMethod: SaveMethod) {
    // validate
    rowElement.markAllAsTouched();
    rowElement.updateValueAndValidity();
    if (rowElement.invalid) {
      return;
    }
    const requestPayload = new CategoryApiModel(
      rowElement.value.categoryId,
      rowElement.value.categoryName,
      rowElement.value.categoryPriorityOrder,
      rowElement.value.group,
    );

    const filteredRequestPayload = PhonebookService.filterRequestPayload(rowElement.value.categoryId, rowElement.value.categoryId, requestPayload);

    if (PhonebookService.isNewEntry(rowElement.value)) {
      this.store.dispatch(createCategoryData({
        id: rowElement.value.categoryId,
        category: filteredRequestPayload as Omit<CategoryApiModel, "id" | "categoryPriorityOrder">,
      }));
    } else {
      this.store.dispatch(updateCategoryData({ category: filteredRequestPayload as CategoryApiModel, saveMethod }));
    }
  }

  groupArrayToSingleGroup(group: Group[]): Group {
    if (group.includes("TrainDriver") && group.includes("NonTrainDriver") || group.includes("Both")) {
      return "Both";
    }
    return group.filter(Boolean)[0];
  }

  groupToArray(group: Group): Group[] {
    if (group === "Both") {
      return [
        "TrainDriver",
        "NonTrainDriver",
      ]
    }
    return [group];
  }

  generateContactWithValidation(category: Omit<CategoryModel, "contacts">) {
    return this.fb.nonNullable.group({
      categoryId: this.fb.nonNullable.control(category.categoryId),
      categoryName: this.fb.nonNullable.control(category.categoryName, Validators.required),
      group: this.fb.nonNullable.control(category.group, Validators.required),
      categoryPriorityOrder: this.fb.nonNullable.control(category.categoryPriorityOrder),
    })
  }

  createNewCategoryRow() {
    const newCategoryId = `new_${this.tableSource.data.length}`;
    const newEmptyCategory = this.generateContactWithValidation({
      categoryId: newCategoryId,
      categoryName: "",
      group: "",
      categoryPriorityOrder: null,
    });
    newEmptyCategory.markAsDirty();
    this.tableItems.push(newEmptyCategory);
    this.table.renderRows();

    // todo: enable edit-mode on current row only + focus
    this.store.dispatch(addEditingRowsData({ rowId: newCategoryId }));
  }

  isChecked(rowGroup: Group, checkboxValue: 'NonTrainDriver' | 'TrainDriver') {
    return [checkboxValue, "Both"].includes(rowGroup);
  }

  mapToReadableText(serverGroupName: Group) {
    switch (serverGroupName) {
      case "TrainDriver":
        return "Lokförare"
      case "NonTrainDriver":
        return "Stationsvärd"
      case "Both":
        return "Alla"
      case "":
        return "";
    }
  }

  applyFilter(filter: string) {
    this.tableSource.filter = filter.trim().toLowerCase();
  }

  ngOnDestroy(): void {
    this.store.dispatch(resetEditingRowsData());
  }

}
