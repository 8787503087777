import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
  WritableSignal
} from "@angular/core";
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {CategoryModel, Contact} from "../../../../models/CategoryModel";
import {Store} from "@ngrx/store";
import {
  addEditingRowsData,
  createContactData,
  deleteContactData,
  fetchCategoriesData,
  removeEditingRowsData,
  resetEditingRowsData,
  updateContactData
} from "../../../../store/categories/categories.action";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {selectCategoriesData, selectEditingRowsData} from "../../../../store/categories/categories.selector";
import {ContactModel} from "../../../../models/contactModel";
import {PhonebookService} from "../../../../services/phonebook.service";
import {ContactApiModel} from "../../../../models/api-contact.model";
import {SaveMethod} from "../../../../types/contacts-types";
import {DialogService} from "../../../../services/dialog.service";

type ContactFormGroup = FormGroup<{
  id: FormControl<string>;
  name: FormControl<string>;
  description: FormControl<string>;
  phoneNumber: FormControl<string>;
  phoneNumberNote: FormControl<string>;
  priorityOrder: FormControl;
  categoryId: FormControl<string>;
  visible: FormControl<boolean>;
}>;

@Component({
  selector: "contacts-table",
  templateUrl: "./contacts-table.component.html",
  styleUrls: ["./contacts-table.component.scss", '../../shared/shared.scss'],
})
export class ContactsTableComponent implements OnInit, OnDestroy {
  protected readonly PhonebookTableService = PhonebookService;
  @Input() categoryId!: string;
  @ViewChild(MatTable) table!: MatTable<ContactModel>;
  editingRowsData$: Observable<string[]>;
  editingRows: string[] = [];
  categoriesData$: Observable<CategoryModel[]>;
  categories!: CategoryModel[];
  dragDisabled = true;
  displayedColumns: string[] = [
    "position",
    "name",
    "description",
    "category",
    "phoneNumber",
    "phoneNumberNote",
    "actions",
  ];
  tableSource: MatTableDataSource<ContactFormGroup> = new MatTableDataSource();
  tableItems!: FormArray<ContactFormGroup>;
  categoryOptions: WritableSignal<Array<{ value: string; text: string }>> = signal([]);

  constructor(private store: Store, private fb: FormBuilder, private dialogService: DialogService) {
    this.categoriesData$ = this.store.select(selectCategoriesData);
    this.editingRowsData$ = this.store.select(selectEditingRowsData);
  }

  async ngOnInit(): Promise<void> {
    this.store.dispatch(fetchCategoriesData());

    this.editingRowsData$.subscribe(editingRows => {
      this.editingRows = editingRows;
    });

    this.categoriesData$.subscribe(categoriesData => {
      if (!categoriesData.length) return;
      const unsavedNewRowIds = this.tableItems?.value.filter(item => this.editingRows.includes(item.id!) && item.id?.startsWith('new_')) || [];
      const unsavedNewRowGroups = unsavedNewRowIds.map(existingNewUnsavedContact => {
        // Keep any existing new row values from client (except always nullify priorityOrder because new row)
        const editingRow = this.generateContactWithValidation({
          categoryId: existingNewUnsavedContact.categoryId!,
          id: existingNewUnsavedContact.id!,
          name: existingNewUnsavedContact.name!,
          description: existingNewUnsavedContact.description! || '',
          phoneNumber: existingNewUnsavedContact.phoneNumber!,
          phoneNumberNote: existingNewUnsavedContact.phoneNumberNote! || '',
          visible: true,
          priorityOrder: null,
        });
        editingRow.markAsDirty();
        return editingRow;
      });

      this.categories = categoriesData;
      this.categoryOptions.set([...categoriesData]
        .sort((a, b) => PhonebookService.compare(a.categoryPriorityOrder!, b.categoryPriorityOrder!, true))
        .map(category => ({
          value: category.categoryId,
          text: category.categoryName,
        })));
      const categoryContacts = categoriesData.find(category => category.categoryId === this.categoryId)?.contacts || [];
      this.tableItems = new FormArray([...categoryContacts]
        .sort((a, b) => PhonebookService.compare(a.priorityOrder, b.priorityOrder, true))
        .map((contact) => {
          const isEditedRow = this.editingRows.includes(contact.id);
          const existingEditedContact = isEditedRow && this.tableItems?.value.find(item => item.id === contact.id);

          if (existingEditedContact) {
            // Keep any existing edited values from client (except always take priorityOrder & visible from server!)
            const editingRow = this.generateContactWithValidation({
              categoryId: existingEditedContact.categoryId!,
              id: existingEditedContact.id!,
              name: existingEditedContact.name!,
              description: existingEditedContact.description! || '',
              phoneNumber: existingEditedContact.phoneNumber!,
              phoneNumberNote: existingEditedContact.phoneNumberNote! || '',
              visible: contact.visible!,
              priorityOrder: contact.priorityOrder,
            });
            editingRow.markAsDirty();
            return editingRow;
          }

          // Else generate new fresh rows based on server data
          return this.generateContactWithValidation({
            categoryId: contact.categoryId,
            id: contact.id,
            name: contact.name,
            description: contact.description || '',
            phoneNumber: contact.phoneNumber,
            phoneNumberNote: contact.phoneNumberNote || '',
            visible: contact.visible,
            priorityOrder: contact.priorityOrder,
          });
        }).concat([...unsavedNewRowGroups]));

      this.tableSource.data = this.tableItems.controls;
      this.tableSource.filterPredicate = (data: FormGroup, filter: string) => {
        const searchableKeys = ['name', 'description', 'phoneNumber', 'phoneNumberNote'];
        return PhonebookService.filterPredicate(data, filter, searchableKeys);
      };
    })
  }

  /**
   * Before exiting site (reload), checks if table has active changes and gives warning.
   * https://developer.mozilla.org/en-US/docs/Web/API/BeforeUnloadEvent
   */
  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHandler(event: BeforeUnloadEvent) {
    event.stopPropagation();

    return !this.hasUnsavedChanges();
  }

  getCategoryName(categoryId: string) {
    return this.categories.find(category => category.categoryId === categoryId)?.categoryName;
  }

  onDrop(event: CdkDragDrop<FormGroup>) {
    // Return the drag container to be disabled.
    this.dragDisabled = true;
    const { isNew } = PhonebookService.onDrop(this.tableSource, "priorityOrder", event);
    if (!isNew) {
      this.save(event.item.data, "Drop");
    }
  }

  reset(id: string, element?: AbstractControl) {
    this.store.dispatch(removeEditingRowsData({ rowId: id }));
    PhonebookService.resetRow(this.tableItems, element!, id);
    this.table.renderRows();
  }

  edit(id: string) {
    this.store.dispatch(addEditingRowsData({ rowId: id }));
  }

  delete(rowElement: Contact) {
    if (PhonebookService.isNewEntry(rowElement)) {
      // Remove from table array if new row, skip showing confirmation dialog
      const newId = this.tableItems.getRawValue().findIndex(item => item.id.includes(rowElement.id));
      if (newId >= 0) {
        this.tableItems.removeAt(newId);
        this.table.renderRows();
      }
    } else if (this.dialogService.openDeleteConfirmation(rowElement.name)) {
      this.store.dispatch(deleteContactData({ contactId: rowElement.id }));
    }
  }

  save(rowElement: AbstractControl<CategoryModel["contacts"][number]>, saveMethod: SaveMethod) {
    // validate
    rowElement.markAllAsTouched();
    rowElement.updateValueAndValidity();
    if (rowElement.invalid) {
      return;
    }

    const requestPayload = new ContactApiModel(
      rowElement.value.id,
      rowElement.value.name,
      rowElement.value.description,
      rowElement.value.phoneNumber,
      rowElement.value.phoneNumberNote,
      rowElement.value.categoryId,
      rowElement.value.visible,
      rowElement.value.priorityOrder,
    )

    const filteredRequestPayload = PhonebookService.filterRequestPayload(rowElement.value.categoryId, rowElement.value.id, requestPayload, this.categoryId);

    if (PhonebookService.isNewEntry(rowElement.value)) {
      this.store.dispatch(createContactData({
        id: rowElement.value.id,
        contact: filteredRequestPayload as Omit<ContactApiModel, "id" | "priorityOrder">,
      }));
    } else {
      this.store.dispatch(updateContactData({ contact: filteredRequestPayload as ContactApiModel, saveMethod }));
    }
  }

  generateContactWithValidation(contact: ContactModel) {
    return this.fb.group({
      id: this.fb.nonNullable.control(contact.id),
      name: this.fb.nonNullable.control(contact.name, [Validators.required]),
      description: this.fb.nonNullable.control(contact.description),
      categoryId: this.fb.nonNullable.control(contact.categoryId || this.categoryId),
      visible: this.fb.nonNullable.control(contact.visible),
      phoneNumber: this.fb.nonNullable.control(contact.phoneNumber, [Validators.required, Validators.pattern(/^\S[\d -]{1,20}$/)]),
      phoneNumberNote: this.fb.nonNullable.control(contact.phoneNumberNote),
      priorityOrder: this.fb.nonNullable.control(contact.priorityOrder),
    })
  }

  createNewContactRow() {
    const newContactId = `new_${this.tableSource.data.length}`;
    const newEmptyContact = this.generateContactWithValidation({
      id: newContactId,
      name: "",
      description: "",
      categoryId: this.categoryId,
      visible: true,
      phoneNumber: "",
      phoneNumberNote: "",
      priorityOrder: null,
    });
    newEmptyContact.markAsDirty();
    this.tableItems.push(newEmptyContact);
    this.table.renderRows();

    // todo: enable edit-mode on current row only + focus
    this.store.dispatch(addEditingRowsData({ rowId: newContactId }));
  }

  getVisibilityTooltipText(visible: boolean, contactId: string) {
    if (this.editingRows.includes(contactId)) {
      return visible ? 'Dölj kontakt' : 'Aktivera dold kontakt'
    }
    return visible ? '' : 'Kontakten är dold'
  }

  setVisibility(element: AbstractControl) {
    element.get('visible')?.patchValue(!element.value.visible);
    this.save(element, "Regular");
  }

  hasUnsavedChanges() {
    return this.tableItems.dirty;
  }

  applyFilter(filter: string) {
    this.tableSource.filter = filter.trim().toLowerCase();
  }

  ngOnDestroy(): void {
    this.store.dispatch(resetEditingRowsData());
  }

}
