export enum MessageStatus {
  planned = "planned",
  active = "active",
  closed = "closed"
}

export enum MessageType {
  TI = "TI",
  TOT = "TOT"
}

export enum TrafficType {
  pendeltag = "pendeltag"
}
