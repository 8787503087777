export enum MessageStatus {
  planned,
  active,
  closed
}

export enum MessageType {
  "TI",
  "TOT"
}

export enum TrafficType {
  pendeltag
}
