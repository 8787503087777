import { Component, OnInit } from "@angular/core";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { Subscription } from "rxjs";

@Component({
  selector: "app-user-info",
  templateUrl: "./user-info.component.html",
  styleUrls: ["./user-info.component.scss"]
})
export class UserInfoComponent  {
  private subscription: Subscription | undefined;
  userData;
  url = "https://graph.microsoft.com/v1.0/me";

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) {}
  //extremely important to unsubscribe
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
