<div class="sticky">
  <button
    class="confirm-button filter-create-button"
    *ngIf="!isReadOnly()"
    (click)="createMessage()"
  >
    <mat-icon>add</mat-icon> Skapa nytt meddelande
  </button>
  <div class="top-filter-box">
    <h3>
      Visa trafikinfomeddelanden
      <span class="message-count">{{ messageCount }} st</span>
    </h3>
    <table>
      <th></th>
      <th>Sortera på</th>
      <tbody>
      <tr>
        <td>
          <div class="checkbox-group">
            <button
              class="transparent-button-light-big checkbox"
              (click)="onChangeMessageStatus('showActive')"
            >
              <mat-icon class="button-checkbox" *ngIf="showActive"
              >check_box</mat-icon
              >
              <mat-icon class="button-checkbox" *ngIf="!showActive"
              >check_box_outline_blank</mat-icon
              >
              <span class="button-checkbox">Aktiva</span>
            </button>

            <button
              class="transparent-button-light-big checkbox"
              (click)="onChangeMessageStatus('showClosed')"
            >
              <mat-icon class="button-checkbox" *ngIf="showClosed"
              >check_box</mat-icon
              >
              <mat-icon class="button-checkbox" *ngIf="!showClosed"
              >check_box_outline_blank</mat-icon
              >
              <span class="button-checkbox">Stängda</span>
            </button>
            <button
              class="transparent-button-light-big checkbox"
              (click)="onChangeMessageStatus('showPlanned')"
            >
              <mat-icon class="button-checkbox" *ngIf="showPlanned"
              >check_box</mat-icon
              >
              <mat-icon class="button-checkbox" *ngIf="!showPlanned"
              >check_box_outline_blank</mat-icon
              >
              <span class="button-checkbox">Planerade</span>
            </button>
          </div>
        </td>
        <td style="width: 15vw;">
          <mat-select
            [value]="sortingOptions[0].value"
            (selectionChange)="changeSorting($event)"
            class="form-control"
          >
            <mat-option
              *ngFor="let sorting of sortingOptions"
              [value]="sorting.value"
            >
              {{ sorting.displayName }}
            </mat-option>
          </mat-select>
        </td>
      </tr>
      </tbody>
    </table>

    <div *ngIf="showFilter" class="hidden-filter-box">
      <table>
        <th>Skickat till</th>
        <th>Rubrik/Händelse</th>
        <tbody>
        <td>
          <div class="checkbox-group">
            <button
              class="transparent-button-light-big checkbox"
              (click)="onChangeMessageType('showApp')"
            >
              <mat-icon class="button-checkbox" *ngIf="showApp"
              >check_box</mat-icon
              >
              <mat-icon class="button-checkbox" *ngIf="!showApp"
              >check_box_outline_blank</mat-icon
              >
              <span class="button-checkbox">Appen</span>
            </button>
            <button
              class="transparent-button-light-big checkbox"
              (click)="onChangeMessageType('showTOT')"
            >
              <mat-icon class="button-checkbox" *ngIf="showTOT"
              >check_box</mat-icon
              >
              <mat-icon class="button-checkbox" *ngIf="!showTOT"
              >check_box_outline_blank</mat-icon
              >
              <span class="button-checkbox">Tåg i Tid</span>
            </button>
          </div>
        </td>
        <td>
          <input
            class="form-control"
            [(ngModel)]="filter.heading"
            (keyup.enter)="onEnter()"
          />
        </td>
        </tbody>
      </table>
      <table>
        <th>Plats</th>
        <th>Berörda</th>
        <th>Skapad/Uppdaterad av</th>
        <tbody>
        <td>
          <input
            class="form-control"
            [(ngModel)]="filter.location"
            (keyup.enter)="onEnter()"
          />
        </td>
        <td>
          <input
            class="form-control"
            [(ngModel)]="filter.affects"
            (keyup.enter)="onEnter()"
          />
        </td>
        <td>
          <input
            class="form-control"
            [(ngModel)]="filter.createdOrUpdatedBy"
            (keyup.enter)="onEnter()"
          />
        </td>
        </tbody>
      </table>
      <table>
        <th>Visas från</th>
        <th>Visas till</th>
        <th>Senast uppdaterad</th>
        <tbody>
        <td>
          <div class="input-container">
            <p-calendar
              [inputStyle]="{ width: 'calc(100% - 33px)' }"
              [(ngModel)]="filter.from"
              dateFormat="yy-mm-dd"
              [selectOtherMonths]="true"
              [showIcon]="true"
              [monthNavigator]="true"
              [yearNavigator]="true"
              yearRange="2000:2099"
              [locale]="sv"
              [showButtonBar]="true"
              appendTo="body"
              (onSelect)="onDateChange($event, 'from')"
              (onClearClick)="onDateChange(null, 'from')"
              showTransitionOptions="0ms ease-out"
              hideTransitionOptions="0ms ease-in"
              [readonlyInput]="true"
              (keyup.enter)="onEnter()"
            ></p-calendar>
          </div>
        </td>
        <td>
          <div class="input-container">
            <p-calendar
              [inputStyle]="{ width: 'calc(100% - 33px)' }"
              [(ngModel)]="filter.to"
              dateFormat="yy-mm-dd"
              [selectOtherMonths]="true"
              [showIcon]="true"
              [monthNavigator]="true"
              [yearNavigator]="true"
              yearRange="2000:2099"
              [locale]="sv"
              [showButtonBar]="true"
              appendTo="body"
              (onSelect)="onDateChange($event, 'to')"
              (onClearClick)="onDateChange(null, 'to')"
              showTransitionOptions="0ms ease-out"
              hideTransitionOptions="0ms ease-in"
              [readonlyInput]="true"
              (keyup.enter)="onEnter()"
            ></p-calendar>
          </div>
        </td>
        <td>
          <div class="input-container">
            <p-calendar
              [inputStyle]="{ width: 'calc(100% - 33px)' }"
              [(ngModel)]="filter.lastUpdatedAt"
              dateFormat="yy-mm-dd"
              [showIcon]="true"
              [selectOtherMonths]="true"
              [monthNavigator]="true"
              [yearNavigator]="true"
              yearRange="2000:2099"
              [locale]="sv"
              [showButtonBar]="true"
              appendTo="body"
              (onSelect)="onDateChange($event, 'lastUpdatedAt')"
              (onClearClick)="onDateChange(null, 'lastUpdatedAt')"
              showTransitionOptions="0ms ease-out"
              hideTransitionOptions="0ms ease-in"
              [readonlyInput]="true"
              (keyup.enter)="onEnter()"
            ></p-calendar>
          </div>
        </td>
        </tbody>
      </table>
    </div>
    <div class="bottom-filter-box">
      <button
        (click)="toggleExpansion()"
        class="transparent-button-light-big"
        style="float: left;margin-top: 10px;padding: 1px 6px;"
      >
        <mat-icon class="chevron" *ngIf="!showFilter"
        >keyboard_arrow_down</mat-icon
        >
        <mat-icon class="chevron" *ngIf="showFilter"
        >keyboard_arrow_up</mat-icon
        >
        <span style="vertical-align: middle;" *ngIf="!showFilter"
        >Fler filter
          <span *ngIf="hasHiddenFilter()" style="color:red"
          >*ett eller flera är applicerade</span
          ></span
        >
        <span style="vertical-align: middle;" *ngIf="showFilter"
        >Färre filter</span
        >
      </button>
      <button
        *ngIf="showFilter"
        style="float:right; margin-top:10px;"
        class="confirm-button"
        (click)="onFilter()"
      >
        <mat-icon class="icon-with-space">search</mat-icon>
        Sök
      </button>
      <button
        *ngIf="showFilter"
        style="float:right; margin-top:10px; margin-right:10px;"
        class="clear-button"
        (click)="onClear()"
      >
        <mat-icon class="icon-with-space">close</mat-icon>
        Rensa
      </button>
    </div>
  </div>
</div>
