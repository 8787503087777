<form [formGroup]="categories2">

  <ng-container formArrayName="hej">
    <mat-table
      #table
      cdkDropList
      cdkDropListLockAxis="y"
      (cdkDropListDropped)="drop($event)"
      [cdkDropListDisabled]="dragDisabled">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <ng-container matColumnDef="position" [sticky]="true">
        <mat-header-cell *matHeaderCellDef>Position</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-icon class="dragCursor" (mousedown)="dragDisabled = false">reorder</mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Kategori</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.get('categoryName').value }}
          <span *ngIf="mode !== 'edit' || !editingRows.has(element.get('categoryId'))"
                formGroupName="categoryName"></span>
          <mat-form-field *ngIf="mode === 'edit' && editingRows.has(element.get('categoryId'))">
            <mat-label>Kategori</mat-label>
            <input matInput [formGroup]="element.get('97a265f4-9150-4542-bf4a-e8442390c2fd').get('categoryName')"/>
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="showTo">
        <mat-header-cell *matHeaderCellDef>Visas för</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngIf="mode !== 'edit' || !editingRows.has(element.get('categoryId'))">{{ element.get('group') }}</span>
          <mat-form-field *ngIf="mode === 'edit' && editingRows.has(element.get('categoryId'))">
            <mat-label>Visas för</mat-label>
            <mat-checkbox
              [checked]=""
              (change)="updateCheck($event.checked, element.get('categoryId'))"
            >
              Lokförare
            </mat-checkbox>
            <mat-checkbox
              [checked]="true"
              (change)="updateCheck($event.checked, element.get('categoryId'))"
            >
              Stationsvärd
            </mat-checkbox>
            <input matInput formControlName="group"/>
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Åtgärder</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button *ngIf="mode === 'readonly'" (click)="click('edit', element.get('categoryId'))">Edit</button>
          <button *ngIf="mode === 'edit'" (click)="click('save', element.get('categoryId'))">Save</button>
          <button (click)="click('delete', element.get('categoryId'))">Delete</button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"/>
      <mat-row
        *matRowDef="let row; columns: displayedColumns;"
        cdkDrag
        [cdkDragData]="row"
      />
    </mat-table>
  </ng-container>
</form>
