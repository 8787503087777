import { Injectable } from "@angular/core";
import { MessageModel } from "../models/message.model";
import { MessageApiModel } from "../models/api-message.model";
import { MessageApiFilter } from "../models/api-message-filter.model";
import { MessageFilter } from "../models/message-filter.model";

@Injectable({
  providedIn: "root"
})
export class UtilityService {
  constructor() { }

  /**
   * Weekdays needs to have sunday as the first day
   */
  public static svLocale = {
    firstDayOfWeek: 1,
    dayNames: [
      "söndag",
      "måndag",
      "tisdag",
      "onsdag",
      "torsdag",
      "fredag",
      "lördag"
    ],
    dayNamesShort: ["sön", "mån", "tis", "ons", "tor", "fre", "lör"],
    dayNamesMin: ["S", "M", "Ti", "O", "To", "F", "L"],
    monthNames: [
      "januari",
      "februari",
      "mars",
      "april",
      "maj",
      "juni",
      "juli",
      "augusti",
      "september",
      "oktober",
      "november",
      "december"
    ],
    monthNamesShort: [
      "jan",
      "feb",
      "mar",
      "apr",
      "maj",
      "jun",
      "jul",
      "agu",
      "sep",
      "okt",
      "nov",
      "dec"
    ],
    today: "Nu",
    clear: "Rensa"
  };

  public static validDate(dateToCheck: Date) {
    if (
      dateToCheck &&
      !dateToCheck
        .toString()
        .includes("0001-01-01") &&
      !dateToCheck
        .toString()
        .includes("01-01-0001") &&
      !dateToCheck
        .toString()
        .includes("0001/01/01") &&
      !dateToCheck
        .toString()
        .includes("01/01/0001") &&
      !dateToCheck
        .toString()
        .toLowerCase()
        .includes("invalid date")
    ) {
      return true;
    } else {
      return false;
    }
  }

  public static equals(obj1: any, obj2: any): boolean {
    if (typeof obj1 !== typeof obj2) {
      return false;
    }
    if (
      (obj1 === undefined && obj2 !== undefined) ||
      (obj2 === undefined && obj1 !== undefined) ||
      (obj1 === null && obj2 !== null) ||
      (obj2 === null && obj1 !== null)
    ) {
      return false;
    }
    if (typeof obj1 === "object") {
      if (Array.isArray(obj1)) {
        if (!Array.isArray(obj2) || obj1.length !== obj2.length) {
          return false;
        }
        for (let i = 0; i < obj1.length; i++) {
          if (!this.equals(obj1[i], obj2[i])) {
            return false;
          }
        }
      } else {
        for (let prop in obj1) {
          if (obj1.hasOwnProperty(prop)) {
            if (!obj2.hasOwnProperty(prop)) {
              return false;
            }

            if (obj1[prop] instanceof Date && obj2[prop] instanceof Date) {
              const date1 = new Date(obj1[prop]);
              const date2 = new Date(obj2[prop]);
              const totalMinutes1 = Math.floor(date1.valueOf() / 1000 / 60);
              const totalMinutes2 = Math.floor(date2.valueOf() / 1000 / 60);
              // Only compare with precision down to minutes
              if (totalMinutes1 !== totalMinutes2) {
                //console.log("Debug! Dates not equal:", date1.valueOf(), date2.valueOf());
                return false;
              }
            } else if (!this.equals(obj1[prop], obj2[prop])) {
              return false;
            }
          }
        }
        for (let prop in obj2) {
          if (obj2.hasOwnProperty(prop)) {
            if (!obj1.hasOwnProperty(prop)) {
              return false;
            }
          }
        }
      }
      return true;
    }
    return obj1 === obj2;
  }

  /**
   * @description Method to check two dates against each other.
   * Useful for comparing i.e. validity from and to.
   * @param {Date=} firstDate Initial date. This is the one that is supposed to be smaller.
   * @param {Date=} lastDate Last date. This is the one that is supposed to be larger.
   * @returns {*} Boolean which states if firstDate is before lastDate.
   * If one date is missing, the method returns true.
   */
  public static compareDates(firstDate: Date, lastDate: Date): boolean {
    if (
      firstDate &&
      lastDate &&
      this.validDate(firstDate) &&
      this.validDate(lastDate)
    ) {
      return firstDate.valueOf() < lastDate.valueOf();
    } else {
      return true;
    }
  }

  public static setMessageApiModel(message: MessageModel) {

    var result = new MessageApiModel();

    result.body = message.body;
    result.createdAt = this.getOffsetTime(message.createdAt);
    result.createdBy = message.createdBy;
    result.heading = message.heading;
    result.id = message.id;
    result.info = message.info;
    result.sendMail = message.sendMail ?? false;
    result.type = message.type;
    result.updatedAt = this.getOffsetTime(message.updatedAt);
    result.updatedBy = message.updatedBy;
    result.validFrom = this.getOffsetTime(message.validFrom);
    result.validTo = this.getOffsetTime(message.validTo);
    result.ver = message.ver;

    return result;
  }

  public static setMessageApiFilter(message: MessageFilter) {

    var result = new MessageApiFilter();

    result.messageStatusesList = message.messageStatusesList;
    result.from = this.getOffsetTime(message.from);
    result.to = this.getOffsetTime(message.to);
    result.filterForce = message.filterForce;
    result.heading = message.heading;
    result.location = message.location;
    result.affects = message.affects;
    result.createdOrUpdatedBy = message.createdOrUpdatedBy;
    result.lastUpdatedAt = this.getOffsetTime(message.lastUpdatedAt);
    result.messageTypeList = message.messageTypeList;
    result.trafficTypeList = message.trafficTypeList;

    return result;
  }

  static getOffsetTime(date) {

    if (date == null) return date;

    if (typeof (date) == "string")
      date = new Date(date);

    var day = this.addDateLeadingZero(date.getDate());
    var month = this.addDateLeadingZero(date.getMonth() + 1); // add 1 as '.getMonth()' is zero indexed
    var year = this.addDateLeadingZero(date.getFullYear());
    var hour = this.addDateLeadingZero(date.getHours());
    var minute = this.addDateLeadingZero(date.getMinutes());
    var second = this.addDateLeadingZero(date.getSeconds());
    var milliSecond = this.addDateLeadingZero(date.getMilliseconds());

    var resOffset = date.getTimezoneOffset();
    var os = Math.abs(resOffset) / 60 > 1 ? "2" : "1";
    var offset = '0' + os + ':00';

    return year + "-" + month + "-" + day + "T" + hour + ':' + minute + ':' + second + '.' + milliSecond + '+' + offset;
  }

  static addDateLeadingZero(number: number) {
    return number < 10 ? "0" + number : number.toString();
  }

  public static removeElementFromArray(array, element) {
    const index = array.indexOf(element);

    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  public static createMessageCopy(message: MessageModel) {
    let newMessage = JSON.parse(JSON.stringify(message));

    // Convert newMessage validFrom/To from string to Date.
    this.ensureMessageFromToDatesAreDateType(newMessage);

    return newMessage;
  }

  static ensureMessageFromToDatesAreDateType(message: MessageModel) {
    // Convert message validFrom/To from string to Date.
    if (typeof (message.validFrom) == typeof ("string"))
      message.validFrom = new Date(Date.parse(message.validFrom!.toString()));
    if (typeof (message.validTo) == typeof ("string"))
      message.validTo = new Date(Date.parse(message.validTo!.toString()));
  }
}
