export enum Page {
  home,
  trafficInfo,
  positionRequest,
  trainOnTimeAdmin,
  contacts
}

export enum PageName{
  "" = 0,
  "trafikinfo" = 1,
  "positionsforfragning" = 2,
  "tagitidadmin" = 3,
  "kontakter" = 4,
}
