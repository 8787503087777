import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackbarComponent} from "../shared/snackbar/snackbar.component";

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  DURATION = 5000;

  constructor(private snackbar: MatSnackBar) { }

  showSuccess(text: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: {
        text,
        icon: "check",
      },
      duration: this.DURATION,
      panelClass: 'success-snackbar',
    });
  }

  showError(text: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: {
        text,
        icon: "error",
      },
      duration: this.DURATION,
      panelClass: 'error-snackbar',
    });
  }
}
