import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MessageFilter } from "../../../../models/message-filter.model";
import {
  MessageStatus,
  MessageType,
  TrafficType
} from "../../../../enums/traffic-info-enums";
import { AuthService } from "../../../../services/auth.service";
import { UtilityService } from "../../../../services/utility.service";
import { MessageService } from "../../../../services/message.service";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"]
})
export class FilterComponent implements OnInit {
  sv: any;
  locale = "sv";
  public showActive = true;
  public showPlanned = false;
  public showClosed = false;

  public showFilter = false;

  public showApp = true;
  public showTOT = true;

  public showPendeln = false;

  @Input() messageCount: number = 0;
  @Output() filterExpansionChange: EventEmitter<boolean> = new EventEmitter();
  @Output() filterChange: EventEmitter<MessageFilter> = new EventEmitter();
  @Output() sortChange: EventEmitter<string> = new EventEmitter();
  @Output() createMessageTrigger: EventEmitter<string> = new EventEmitter(); //emit ID = null

  filter = new MessageFilter(
    [MessageStatus.active],
    null,
    null,
    true,
    null,
    null,
    null,
    null,
    null,
    [MessageType.TI, MessageType.TOT],
    [TrafficType.pendeltag]
  );
  sortingOptions = [
    {
      displayName: "Visas från (senast överst)",
      value: { orderType: "from", revertedOrder: true }
    },
    {
      displayName: "Senast uppdaterad (senast överst)",
      value: { orderType: "lastUpdatedAt", revertedOrder: true }
    },
    {
      displayName: "Rubrik (A till Ö)",
      value: { orderType: "heading", revertedOrder: false },
    }
  ];
  constructor(
    private authService: AuthService,
    private messageService: MessageService
  ) {
    this.sv = UtilityService.svLocale;
  }

  ngOnInit() {
  }

  onChangeMessageStatus(statusToToggle) {
    this[statusToToggle] = !this[statusToToggle];
    this.filter.messageStatusesList = [];
    if (this.showActive) {
      this.filter.messageStatusesList.push(MessageStatus.active);
    }

    if (this.showClosed) {
      this.filter.messageStatusesList.push(MessageStatus.closed);
    }

    if (this.showPlanned) {
      this.filter.messageStatusesList.push(MessageStatus.planned);
    }

    this.onFilter();
  }

  onChangeMessageType(statusToToggle) {
    this[statusToToggle] = !this[statusToToggle];
    this.filter.messageTypeList = [];
    if (this.showApp) {
      this.filter.messageTypeList.push(MessageType.TI);
    }

    if (this.showTOT) {
      this.filter.messageTypeList.push(MessageType.TOT);
    }

    if (!this.showApp && !this.showTOT) {
      this.showApp = true;
      this.showTOT = true;
      this.filter.messageTypeList = [MessageType.TI, MessageType.TOT];
    }
  }

  toggleExpansion() {
    console.log("toggle");
    this.showFilter = !this.showFilter;
    this.filterExpansionChange.emit(this.showFilter);
  }

  hasHiddenFilter() {
    return (
      this.filter.affects ||
      this.filter.createdOrUpdatedBy ||
      this.filter.from ||
      this.filter.to ||
      this.filter.heading ||
      this.filter.lastUpdatedAt ||
      this.filter.messageTypeList.length != 2
    );
  }

  changeSorting(event) {
    console.log("sort", event);
    this.sortChange.emit(event.value);
  }

  onFilter() {
    console.log("Emitting", this.filter);
    this.filterChange.emit(this.filter);
  }

  onDateChange(event, property) {
    console.log("Setting", property, "to", event);
    this.filter[property] = event;
  }

  createMessage() {
    console.log('click button')
    this.messageService.clickNewMessage.emit('');
  }

  isReadOnly() {
    return !this.authService.hasReadWriteGroups();
  }

  onClear(){
    this.filter = new MessageFilter(this.filter.messageStatusesList);
    this.filter.messageTypeList = [MessageType.TI, MessageType.TOT];
    this.showApp = true;
    this.showTOT = true;
    this.onFilter();
  }

  onEnter(){
    this.onFilter();
  }
}
