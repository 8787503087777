import { MessageStatus, MessageType, TrafficType } from "../enums/traffic-info-enums";

export class MessageFilter {
  constructor(
    public messageStatusesList: MessageStatus[] = [],
    public from: Date | null = null,
    public to: Date | null = null,
    public filterForce: boolean = false,
    public heading: string | null = null,
    public location: string | null = null,
    public affects: string | null = null,
    public createdOrUpdatedBy: string | null = null,
    public lastUpdatedAt: Date | null = null,
    public messageTypeList: MessageType[] = [],
    public trafficTypeList: TrafficType[] = []
  ) {}
}
