export class MessageInfo {
  constructor(
    public action: string | null = null,
    public affects: string | null = null,
    public alternativeRoute: string | null = null,
    public estimation: string | null = null,
    public incident: string | null = null,
    public location: string | null = null,
    public notifyGroups: string[] = [],
    public replacementBusConfirmed: boolean = false,
    public replacementBusOrdered: boolean = false,
    public ticketCooperation: boolean = false,
    public trafficInfo: string | null = null,
    public silentMtrp: boolean = false
  ) {}
}
