import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MessageModel } from "../../../../models/message.model";
import { AuthService } from "../../../../services/auth.service";
import { MessageService } from "../../../../services/message.service";
import { UtilityService } from "../../../../services/utility.service";
import { MessageFormProperty } from "../../../../types/message-types";
import { ActivatedRoute } from "@angular/router";
import cloneDeep from 'lodash/cloneDeep';

@Component({ template: '' })
export class MessageFormComponent implements OnInit {
  sv: any;
  protected _message: MessageModel = new MessageModel();
  @Input() set message(val: MessageModel) {
    this.messageService.showErrors = false;
    this.messageService.errors.clear();

    //Check for new window or existing message to set up the form with the correct values.
    let routeId = this.activatedRoute.snapshot.paramMap.get("id");
    if (val.id || routeId) {
      this.setUpExistingMessage(val);
    } else {
      this.setUpDefaultValues(val);
      this.messageService.originalMessage = cloneDeep(this.messageService.currentMessage);
    }
  }
  get message(): MessageModel {
    return this._message!;
  }

  @Input() numberOfVersions: number = 1;

  @Output() titleChanged: EventEmitter<string> = new EventEmitter<string>();
  messageForm: FormGroup = null!;
  locale = "sv";

  validTo: any;
  validFrom: any;

  constructor(
    protected authService: AuthService,
    protected messageService: MessageService,
    protected activatedRoute: ActivatedRoute
  ) {
    this.sv = UtilityService.svLocale;
  }

  ngOnInit() {}
  setUpDefaultValues(val: MessageModel) {}
  setUpExistingMessage(val: MessageModel) {}

  getCount(property: MessageFormProperty) {
    if (this.message[property]) {
      return this.message[property].length;
    } else if (this.message.info[property]) {
      return this.message.info[property].length;
    } else {
      return 0;
    }
  }

  showErrors(property) {
    return (
      this.messageService.showErrors && this.messageService.getError(property)
    );
  }

  onDateChange(event, property: MessageFormProperty) {
    this.messageForm.get(property)!.patchValue(event);
    this.message[property] = event;

    console.log("Date change for property", property, event);

    let err =
      !event || UtilityService.validDate(event) ? null : { invalidDate: true };
    this.messageService.setError(property, err);
    this.messageForm.get(property)!.setErrors(err);

    let dateOrderErr = UtilityService.compareDates(
      this.message.validFrom!,
      this.message.validTo!
    )
      ? null
      : { wrongDateOrder: true };
    this.messageService.setError(property, dateOrderErr);
    this.messageForm.get(property)!.setErrors(dateOrderErr);

    if (this.message[property] && this.message[property].setSeconds) this.message[property].setSeconds(0);
  }

  onTextboxPaste(event: ClipboardEvent, property: string, maxLimit: number, required: boolean) {
    // Timeout needed to get pasted text AND text from input.
    // Without timeout only old value will be available.
    setTimeout(() => this.onTextboxChange(event, property, maxLimit, required), 0);
  }

  onTextboxChange(event, property: string, maxLimit: number, required: boolean) {
    const text = event.target.value;
    let error: any = null;
    if (required && (!text || text.length == 0)) {
      error = { required: true };
    } else if (maxLimit && text.length > maxLimit) {
      error = { maxLimit: true };
    }
    this.messageForm.get(property)!.setErrors(error);
    this.messageService.setError(property, error);
    if (this.message.hasOwnProperty(property)) {
      this.message[property] = text;
    } else if (this.message.info.hasOwnProperty(property)) {
      this.message.info[property] = text;
    }
  }

  setInitialErrors(property: MessageFormProperty) {
    if (!this.message[property]) {
      let error = { required: true };
      this.messageForm.get(property)!.setErrors(error);
      this.messageForm.get(property)!.markAsTouched();
      this.messageService.setError(property, error);
    }
  }

  errorMessage(property: MessageFormProperty) {
    let message: any = [];
    let formCtrl = this.messageForm.get(property);
    if (this.messageService.errors.get(property)["required"]) {
      message.push("Måste fyllas i");
    }

    if (formCtrl!.hasError("invalidDate")) {
      message.push("Ogiltigt format");
    }

    if (formCtrl!.hasError("maxLimit")) {
      message.push("Text är för lång");
    }

    if (formCtrl!.hasError("wrongDateOrder")) {
      message.push("Visas till är mindre än visas från");
    }
    return message.join("/");
  }

  isReadOnly() {
    return (
      this.message.ver < this.numberOfVersions || !this.authService.hasReadWriteGroups()
    );
  }

  limitUserInput(event, maxLimit: number) {
    let allowRowbreak = maxLimit >= 100;
    if (!allowRowbreak && event.key == "Enter") {
      event.preventDefault();
    } else if (event.key != "Backspace") {
      if (event.target.innerText.length + 1 > maxLimit) {
        if (event.ctrlKey && (event.key == "KeyX" || event.key == "KeyC")) {
          //DO nothing?
        } else {
          event.preventDefault();
        }
      }
    }
  }

  onDateInputBlur(event, property: MessageFormProperty) {
    const dateString = event.srcElement.value;
    if (dateString === "") {
      this.onDateChange(null, property);
      return;
    }

    const date = new Date(Date.parse(dateString));
    if (UtilityService.validDate(date))
      this.onDateChange(date, property);
  }
}
