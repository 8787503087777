import { Component, OnInit } from "@angular/core";
import { Page, PageName } from "../../enums/core-enums";
import { AuthService } from "../../services/auth.service";
import { MessageService } from "../../services/message.service";
import { MatToolbarModule } from "@angular/material/toolbar";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { DropDownComponent } from "./drop-down/drop-down.component";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  standalone: true,
  imports: [
    MatToolbarModule,
    CommonModule,
    MatButtonModule,
    DropDownComponent,
    MatIconModule
  ],
  styleUrls: ["./menu.component.scss"]
})
export class MenuComponent implements OnInit {
  isExpanded: boolean = false;

  currentPageName = "Välkommen!";

  constructor(
    private authService: AuthService,
    private messageService: MessageService
  ) {}

  logout() {
    if (
      !this.messageService.hasChanges() ||
      confirm(
        "Du har osparade ändringar. Vill du verkligen stänga meddelandet?"
      )
    ) {
      this.authService.logout();
    }
  }

  ngOnInit() {
    let number = PageName[window.location.pathname.substr(1)];
    this.changeHeader(number);
  }

  user() {
    return this.authService.getUserName();
  }

  changeHeader(page: Page) {
    this.isExpanded = false;
    switch (page) {
      case Page.home:
        this.currentPageName = "Välkommen!";
        break;
      case Page.trafficInfo:
        this.currentPageName = "Trafikinfo";
        break;
      case Page.contacts:
        this.currentPageName = "Telefonbok";
        break;
      default:
        this.currentPageName = "";
        break;
    }
  }

  loggedIn() {
    return this.authService.loggedIn;
  }
}
