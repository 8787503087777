import {Component, OnInit} from "@angular/core";
import {CategoryModel} from "../../models/CategoryModel";
import {Store} from "@ngrx/store";
import {selectCategoriesData} from "../../store/categories/categories.selector";
import {Observable} from "rxjs";
import {fetchCategoriesData} from "../../store/categories/categories.action";
import {SortableTableService} from "../shared-table";

@Component({
  selector: "contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.scss"],
})
export class ContactsComponent implements OnInit {
  categoriesData$: Observable<CategoryModel[]>;
  categories: CategoryModel[] = [];
  allExpandState: boolean = false;

  constructor(private store: Store) {
    this.categoriesData$ = this.store.select(selectCategoriesData);
  }

  ngOnInit(): void {
    this.store.dispatch(fetchCategoriesData());

    this.categoriesData$.subscribe(categoriesData => {
      this.categories = [...categoriesData].sort((a, b) => {
        return SortableTableService.compare(a.categoryPriorityOrder!, b.categoryPriorityOrder!, true);
      });
    });
  }

  /**
   * Needed to track an id (instead of object identifier) in order to not close
   * according/re-render whole table when we update sorting.
   */
  trackById(_: number, category: CategoryModel) {
    return category.categoryId;
  }

  toggleAllExpanded() {
    this.allExpandState = !this.allExpandState;
  }
}
