<form [formGroup]="messageForm">
  <label class="blocking">Namn<span *ngIf="!isReadOnly()">*</span>
    <span *ngIf="showErrors('heading')" class="error">{{
      errorMessage("heading")
    }}</span>
    <span class="count" *ngIf="!isReadOnly()">{{ getCount("heading") }}/{{ maxLength.heading }}</span>
  </label>
  <textarea
    [ngClass]="{ 'error-input': showErrors('heading') }"
    *ngIf="!isReadOnly()"
    contenteditable="true"
    class="expanding-textarea"
    id="headingDiv"
    [value]="messageForm.get('heading')!.value || ''"
    [maxLength]="maxLength.heading"
    (paste)="onTextboxPaste($event, 'heading', maxLength.heading, true)"
    (keyup)="onTextboxChange($event, 'heading', maxLength.heading, true)"
    (keydown)="limitUserInput($event, maxLength.heading)"
  ></textarea>

  <textarea
    *ngIf="isReadOnly()"
    disabled
    contenteditable="false"
    [value]="this.messageForm.get('heading')!.value || ''"
    class="expanding-textarea disabled"
    id="headingDiv2"
  ></textarea>
  <label class="blocking">Meddelande<span *ngIf="!isReadOnly()">*</span>
    <span *ngIf="showErrors('body')" class="error">{{
      errorMessage("body")
    }}</span>
    <span class="count" *ngIf="!isReadOnly()">{{ getCount("body") }}/{{ maxLength.body }}</span>
  </label>
  <textarea
    [ngClass]="{ 'error-input': showErrors('body') }"
    *ngIf="!isReadOnly()"
    contenteditable="true"
    class="expanding-textarea"
    id="bodyDiv"
    [value]="messageForm.get('body')!.value"
    [maxLength]="maxLength.body"
    (paste)="onTextboxPaste($event, 'body', maxLength.body, true)"
    (keyup)="onTextboxChange($event, 'body', maxLength.body, true)"
    (keydown)="limitUserInput($event, maxLength.body)"
  ></textarea>

  <textarea
    *ngIf="isReadOnly()"
    disabled
    contenteditable="false"
    [value]="this.messageForm.get('body')!.value || ''"
    class="expanding-textarea disabled"
    id="bodyDiv2"
  ></textarea>

  <div class="split left">
    <label class="blocking">Visas från
      <span *ngIf="showErrors('validFrom')" class="error">{{ errorMessage("validFrom") }}</span>
    </label>
    <div class="input-container">
      <p-datePicker
        formControlName="validFrom"
        (onSelect)="onDateChange($event, 'validFrom')"
        (onClearClick)="onDateChange(null, 'validFrom')"
        (onBlur)="onDateInputBlur($event, 'validFrom')"
        [disabled]="isReadOnly()"
        [selectOtherMonths]="true"
        [showButtonBar]="true"
        [showTime]="true"
        [showIcon]="true"
        dateFormat="yy-mm-dd"
        hourFormat="24"
        placeholder="Nu"
        appendTo="body"
        showTransitionOptions="0ms ease-out"
        hideTransitionOptions="0ms ease-in"
        styleClass="calendar-input"
      />
    </div>
  </div>
  <div class="split right">
    <label class="blocking">Visas till
        <span *ngIf="showErrors('validTo')" class="error">{{ errorMessage("validTo") }}</span>
    </label>
    <div class="input-container">
      <p-datePicker
        formControlName="validTo"
        (onSelect)="onDateChange($event, 'validTo')"
        (onClearClick)="onDateChange(null, 'validTo')"
        (onBlur)="onDateInputBlur($event, 'validTo')"
        [disabled]="isReadOnly()"
        [selectOtherMonths]="true"
        [showButtonBar]="true"
        [showTime]="true"
        [showIcon]="true"
        dateFormat="yy-mm-dd"
        hourFormat="24"
        placeholder="Tills vidare"
        appendTo="body"
        showTransitionOptions="0ms ease-out"
        hideTransitionOptions="0ms ease-in"
        styleClass="calendar-input"
      />
    </div>
  </div>
</form>
