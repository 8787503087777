import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output
} from "@angular/core";
import { MessageStatus, MessageType } from "../../../enums/traffic-info-enums";
import { MessageFilter } from "../../../models/message-filter.model";
import { MessageService } from "../../../services/message.service";
import { UtilityService } from "../../../services/utility.service";
import { MessageOverview } from "../../../models/message-overview.model";

@Component({
  selector: "app-message-list",
  templateUrl: "./message-list.component.html",
  styleUrls: ["./message-list.component.scss"]
})
export class MessageListComponent implements OnInit {
  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.changeMaxMessageHeight(false);
  }
  messageList: MessageOverview[] = [];
  activeAndPlannedList = [];

  activeMessageId: any | null = null;
  filter = new MessageFilter(
    [MessageStatus.active],
    null,
    null,
    true,
    null,
    null,
    null,
    null,
    null,
    [MessageType.TI, MessageType.TOT]
  );

  maxHeight: string = ''; //number as string because of HTML
  @Output() clickMessage: EventEmitter<string> = new EventEmitter(); //emit Message ID

  errorText: string |null = null;

  constructor(private messageService: MessageService) { }

  ngOnInit(): void {
    this.getMessagesWithFilter();
    this.messageService.updateList.subscribe(response => {
      this.activeMessageId = response;
      this.getMessagesWithFilter();
    });
    this.messageService.messageChange.subscribe(response => {
      if (
        (response &&
          response.length > 0 &&
          this.messageService.latestUpdate! < response[0].lastUpdatedAt) ||
        this.messageService.messageLength != response.length ||
        this.hasPlannedNowActive() ||
        this.hasActiveNowClosed(response)
      ) {
        console.log("detected message change");
        this.changeMaxMessageHeight(null);
        this.messageList = response;
        this.messageService.loading = false;
        if (!this.messageList || this.messageList.length == 0) {
          this.errorText = `Hittade inga meddelanden för valt filter`;
        }
        this.onSort({ orderType: "from", revertedOrder: true });
        if (!this.messageService.pollingActive) {
          this.messageService.pollMessageList();
        }
      }
    });

    this.messageService.clickNewMessage.subscribe(response => {
      console.log("list triggered");
      this.onClickNewMessage();
    });
    this.messageService.clickOpenInNewWindow.subscribe(id => {
      this.activeMessageId = null;
      this.messageService.currentMessage = null;
      this.messageService.originalMessage = null;
    });

    this.messageService.useAsTemplate.subscribe(response => {
      this.activeMessageId = null;
      this.messageService.currentMessage = null;
      this.messageService.originalMessage = null;
    });
  }

  ngAfterViewInit() {
    this.changeMaxMessageHeight(false);
  }

  statusToClass(status: MessageStatus) {
    switch (status) {
      case MessageStatus.closed:
        return "red";
      case MessageStatus.active:
        return "green";
      case MessageStatus.planned:
        return "blue";
      default:
        return "";
    }
  }

  getMessageTarget(target: MessageType) {
    let response = { text: "", className: "" };
    switch (target.toString()) {
      case "ti":
        response.text = "Appen";
        response.className = "message-app"; 
        break;
      case "tot":
        response.text = "Tåg i Tid";
        response.className = "message-web"; 
        break;
      default:
        response.text = "ERR";
        response.className = "message-err";
    }
    return response;
  }

  getMessageStatus(status: MessageStatus) {
    switch (status) {
      case MessageStatus.closed:
        return "Släckt";
      case MessageStatus.active:
        return "Aktivt";
      case MessageStatus.planned:
        return "Planerat";
      default:
        return "ERR";
    }
  }

  changeMaxMessageHeight(event) {
    setTimeout(() => {
      let filter = document.getElementById("messageFilter");
      let occupiedHeight = filter!.clientHeight + 10 + 65;
      this.maxHeight = (window.innerHeight - occupiedHeight).toString();
    }, 0);
  }

  onFilter(filter) {
    this.filter = filter;
    this.getMessagesWithFilter();
    console.log("should filter with", filter);
  }

  onSort(event) {
    let orderType = event.orderType;
    let revertedOrder = event.revertedOrder;
    this.messageList.sort((a, b) => {
      if (revertedOrder) {
        if (a[orderType].toLowerCase && b[orderType].toLowerCase) {
          if (a[orderType].toLowerCase() < b[orderType].toLowerCase()) {
            return 1;
          }

          if (a[orderType].toLowerCase() > b[orderType].toLowerCase()) {
            return -1;
          }
        } else {
          if (a[orderType] < b[orderType]) {
            return 1;
          }

          if (a[orderType] > b[orderType]) {
            return -1;
          }
        }
      } else {
        if (a[orderType].toLowerCase && b[orderType].toLowerCase) {
          if (a[orderType].toLowerCase() < b[orderType].toLowerCase()) {
            return -1;
          }

          if (a[orderType].toLowerCase() > b[orderType].toLowerCase()) {
            return 1;
          }
        } else {
          if (a[orderType] < b[orderType]) {
            return -1;
          }

          if (a[orderType] > b[orderType]) {
            return 1;
          }
        }
      }

      return 0;
    });
  }

  refreshMessageListOnStatusChange(messageList) {
    let plannedToActiveChange = this.hasPlannedNowActive();
    let activeToCloseChange = this.hasActiveNowClosed(messageList);

    if (plannedToActiveChange || activeToCloseChange) {
      this.onFilter(this.filter);
    }
  }

  getMessagesWithFilter() {
    //Check that any status exists in filter
    if (
      this.filter.messageStatusesList &&
      this.filter.messageStatusesList.length > 0
    ) {
      this.messageService.loading = true;
      this.errorText = null;

      this.messageService.getAllMessagesAsync(new MessageFilter(
        [MessageStatus.active, MessageStatus.planned],
        null,
        null,
        true,
        null,
        null,
        null,
        null,
        null,
        [MessageType.TI, MessageType.TOT])).subscribe(
        (response: any) => {
          this.activeAndPlannedList = response;
        });

      this.messageService.getAllMessagesAsync(this.filter).subscribe(
        (response: any) => {
          if (response.length == 0) {
            this.errorText = `Hittade inga meddelanden för valt filter`;
          } else {
            response.sort((a, b) =>
              a.lastUpdatedAt > b.lastUpdatedAt ? -1 : 1
            );
            this.messageService.latestUpdate = response[0].lastUpdatedAt;
            this.messageService.messageLength = response.length;
          }

          this.changeMaxMessageHeight(null);
          this.messageList = response;
          this.messageService.loading = false;

          this.onSort({ orderType: "from", revertedOrder: true });
          if (!this.messageService.pollingActive) {
            this.messageService.pollMessageList();
          }
          if (!response.find(x => x.id == this.activeMessageId)) {
            this.onClickMessage(null, true);
          }

          window.setInterval(this.refreshMessageListOnStatusChange.bind(this), 3000, this.messageList);
        },
        error => {
          console.error(JSON.stringify(error));
          this.messageList = [];
          this.messageService.loading = false;
          this.errorText = `Misslyckades med att hämta meddelandelista. Försök igen.`; //Maybe add (Error ${error.status})
        }
      );
    } else {
      this.messageList = [];
      this.errorText = `Ingen status vald i filtret`;
    }
  }

  onClickMessage(id: string | null, ignoreCheck?: boolean) {
    if(id === null){
      console.log('Message id was null when clicking message.');
      return;
    }

    if (this.activeMessageId != id) {
      if (
        ignoreCheck ||
        !this.messageService.hasChanges() ||
        confirm(
          "Du har osparade ändringar. Vill du verkligen stänga meddelandet?"
        )
      ) {
        this.activeMessageId = id;
        this.messageService.currentMessage = null;
        this.messageService.originalMessage = null;
        this.clickMessage.emit(id);
      }
    }
  }

  onClickNewMessage() {
    if (this.activeMessageId != null) {
      this.activeMessageId = null;
    }
  }

  validDate(dateToCheck: Date) {
    return UtilityService.validDate(dateToCheck);
  }

  isLoading() {
    return this.messageService.loading;
  }

  /**
   * Returns true if messageList contains any messages with a from-time before now with status planned.
   */
  private hasPlannedNowActive() {
    const now = new Date();
    let plannedNowActive = this.activeAndPlannedList.filter(
      (m: MessageOverview) =>
        m.messageStatus == MessageStatus.planned &&
        m.from &&
        new Date(m.from).valueOf() < now.valueOf()
    );
    return plannedNowActive && plannedNowActive.length > 0;
  }

  /**
   * Returns true if messageList contains any messages with a to-time before now with status active.
   */
  private hasActiveNowClosed(newList: MessageOverview[]) {
    const now = new Date();
    let activeNowClosed = this.activeAndPlannedList.filter(
      (m: MessageOverview) =>
        (m.messageStatus == MessageStatus.active &&
          m.to &&
          new Date(m.to).valueOf() < now.valueOf())
    );
    return activeNowClosed && activeNowClosed.length > 0;
  }
}
