import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-restricted',
  templateUrl: './restricted.component.html',
  styleUrls: ['./restricted.component.scss']
})
export class RestrictedComponent implements OnInit {

  errorText = `Behörigheter saknas för sidan du försökte besöka.`; 
  constructor() { }

  ngOnInit() {
  }

}
