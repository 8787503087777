import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';
import {MatTable, MatTableModule} from "@angular/material/table";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {NgForOf, NgIf} from "@angular/common";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {Store} from "@ngrx/store";
import {CategoryModel} from "../../../models/CategoryModel";
import {updateCategoriesData} from "../../../store/categories/categories.action";
import {update} from "lodash";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormArray, FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";

type THing = "edit" | "delete" | "save";
type Created = FormGroup<{
  categoryName: FormControl<string | null>;
  position: FormControl<number | null>;
  group: FormControl<"NonTrainDriver" | "TrainDriver" | "Both" | null>;
}>;
type Form = FormGroup<{
  [id: string]: Created
}>;

@Component({
  selector: "category-sortable-table",
  templateUrl: "./category-sortable-table.component.html",
  styleUrls: ["./category-sortable-table.component.scss"],
  standalone: true,
  imports: [
    CdkDropList,
    CdkDrag,
    MatTableModule,
    MatInputModule,
    MatIconModule,
    NgIf,
    MatOptionModule,
    MatSelectModule,
    NgForOf,
    MatCheckboxModule,
    ReactiveFormsModule,
  ]
})
export class CategorySortableTableComponent implements OnInit {
  //@Input() categories!: CategoryModel[];
  @Input() categories!: FormGroup;
  @Input() categories2!: FormGroup<{
    hej: FormArray<FormGroup<{
      position: FormControl<number | null>;
      categoryName: FormControl<string | null>;
      categoryId: FormControl<string | null>;
      group: FormControl<"NonTrainDriver" | "TrainDriver" | "Both" | null>
    }>>
  }>;
  @ViewChild('table') table!: MatTable<CategoryModel>;
  mode = "readonly";
  dragDisabled = true;
  editingRows: Set<CategoryModel["categoryId"]> = new Set();
  displayedColumns: string[] = [
    "position",
    "name",
    "showTo",
    "actions",
  ];

  constructor(private store: Store) {

  }

  ngOnInit(): void {

  }

  drop(event: CdkDragDrop<string>) {
    // Return the drag container to be disabled.
    this.dragDisabled = true;

  }

  click(change: THing, id: string) {
    console.log({change});
    this.mode = change;

    if (change === "edit") {
      this.editingRows.add(id);
    }

    if (change === "save") {
      console.log('save', id);
      this.editingRows.delete(id);

      // todo: add edited class if have unsaved values.
    }
  }

  updateCheck(checked: boolean, categoryId: string) {
    console.log({checked, categoryId})
  }
}
