import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  HostListener
} from "@angular/core";
import { MessageType } from "../../../enums/traffic-info-enums";
import { MessageModel } from "../../../models/message.model";
import { AuthService } from "../../../services/auth.service";
import { MessageService } from "../../../services/message.service";
import { UtilityService } from "../../../services/utility.service";
import { interval, Subscription } from "rxjs";
import { startWith, switchMap } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { NotifyGroupsService } from "src/app/services/notify-groups.service";
import { userReadWriteGroups } from "../../../../assets/user-groups";

@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"]
})
export class MessageComponent implements OnInit {
  private _message: MessageModel[] = [];
  originalMessage: MessageModel | null = null;
  messageType: MessageType | null = null;
  showApp = false;
  isNewMessage = false;
  laterMessageExists: boolean = false;
  numberOfVersions: number = 1;
  currentMessage: MessageModel | null = null;

  @Input() set message(val: MessageModel[]) {
    this.laterMessageExists = false;
    if (val && val.length > 0) {
      if (val[0].id) {
        this.isNewMessage = false;
        this.messageType = MessageType[val[0].type!];
      } else {
        this.isNewMessage = true;
        if (val[0].type) {
          this.messageType = MessageType[val[0].type];
        } else {
          this.messageType = MessageType.TOT;
        }
      }

      this._message = val.sort((a, b) => this.sortByNumber(a, b));
      this.showApp = this.messageType == MessageType.TI;
      this.setCurrentMessage(this.message[this.message.length - 1]);
      this.setOriginalMessage(this.createCopy(this.currentMessage!));
      this.numberOfVersions = val.length;
      if (!this.pollingActive && this.currentMessage!.id) {
        this.pollForNewVersion();
      }
    } else {
      this._message = [];
    }

    if (
      this.pollingActive &&
      (!this._message || !this.currentMessage || !this.currentMessage.id)
    ) {
      this.pollSubscription!.unsubscribe();
    }
  }


  get message() {
    return this._message;
  }

  pollingActive: boolean = false;
  pollingInterval: number = 10000;
  pollSubscription: Subscription | null = null;

  //Before exiting site (reload or X-button or similar), checks if any active changes and gives warning.
  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHandler(event) {
    /*Check if new message in new window and warn
    WARNING: Will not show in chrome if no user interaction was made in the new window.
    https://developer.mozilla.org/en-US/docs/Web/API/BeforeUnloadEvent
    */
    let idFromRoute = this.activatedRoute.snapshot.paramMap.get("id");
    if (idFromRoute && idFromRoute.includes("new_message")) {
      return false;
    }

    //Check if has changes and warn
    if (this.messageService.hasChanges()) {
      return false;
    }

    //No warning in any other cases
    return true;
  }

  constructor(
    private messageService: MessageService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      //Make sure the graphUserGroups are filled for the readOnly restriction.
      if (!this.authService.hasGroupsAsync(userReadWriteGroups.DLI)) {
        this.authService.getGraphUser();
      }

      var id = paramMap.get("id");
      if (id && id.includes("new_message")) {
        this.newMessage();
      } else if (id) {
        this.fetchMessage(id);
      }
    });
    this.messageService.clickOpenInNewWindow.subscribe(id => {
      localStorage.setItem(
        "currentMessage:" + id,
        JSON.stringify(this.currentMessage)
      );
    });
    this.messageService.useAsTemplate.subscribe(id => {
      this.useAsTemplate(id);
    });

    // Start with new message input fields on init.
    this.messageService.clickNewMessage.emit('');
  }

  fetchMessage(id: string) {
    this.messageService.getMessageById(id)
      .subscribe({
        next: (data) => {
          let castData = data as MessageModel[];
          castData.map(x => {
            x.validFrom = new Date(x.validFrom!);
            x.validTo = UtilityService.validDate(x.validTo!)
              ? new Date(x.validTo!)
              : null;
          });
          this.setMessageInNewWindow(castData, id);
        },
        error: (error) => console.error(JSON.stringify(error))
      });
  }

  newMessage() {
    let currentMessageFromLocalStorage = JSON.parse(
      localStorage.getItem("currentMessage:new_message")!
    );
    if (currentMessageFromLocalStorage) {
      this.message = [currentMessageFromLocalStorage];
      localStorage.removeItem("currentMessage:new_message");
    }
  }
  setMessageInNewWindow(val: MessageModel[], id: string) {
    this.currentMessage = JSON.parse(
      localStorage.getItem("currentMessage:" + id)!
    );
    if (this.currentMessage) {
      localStorage.removeItem("currentMessage:" + id);
      this.laterMessageExists = false;
      if (this.currentMessage.id) {
        this.isNewMessage = false;
        this.messageType = MessageType[this.currentMessage.type!];
      } else {
        this.isNewMessage = true;
        if (this.currentMessage.type) {
          this.messageType = MessageType[this.currentMessage.type];
        } else {
          this.messageType = MessageType.TOT;
        }
      }

      this._message = val.sort((a, b) => this.sortByNumber(a, b));
      this.showApp = this.messageType === MessageType.TI;
      this.setOriginalMessage(
        this.createCopy(
          this.message.find(x => x.ver === this.currentMessage!.ver)!
        )
      );
      this.numberOfVersions = val.length;
      if (!this.pollingActive && this.currentMessage.id) {
        this.pollForNewVersion();
      }
      if (
        this.pollingActive &&
        (!this._message || !this.currentMessage || !this.currentMessage.id)
      ) {
        this.pollSubscription!.unsubscribe();
      }
    }
  }

  onChangeMessageType(shouldShowApp) {
    this.showApp = shouldShowApp;
    if (this.showApp) {
      this.messageType = MessageType.TI;
    } else {
      this.messageType = MessageType.TOT;
    }
  }

  changeVersion(newVersionToShow: number) {
    this.laterMessageExists = false;
    let newIndex = newVersionToShow;

    // Get prev/next message based on what is available instead of array index (leads to out of bounds)
    if (this.currentMessage?.ver && newVersionToShow > this.currentMessage.ver) {
      // Want to go to next version
      const currentIndex = this.message.findIndex(x => x.ver === this.currentMessage?.ver);
      newIndex = currentIndex + 1;
    } else if (this.currentMessage?.ver && newVersionToShow < this.currentMessage.ver) {
      // Want to go to prev version
      const currentIndex = this.message.findIndex(x => x.ver === this.currentMessage?.ver);
      newIndex = currentIndex - 1;
    }

    if (this.message[newIndex]) {
      this.setCurrentMessage(this.message[newIndex]);
      this.setOriginalMessage(
        this.createCopy(this.message[newIndex])
      );
    }
  }

  sortByNumber(a, b) {
    return a - b;
  }

  saveChanges(id: string) {
    if (this.messageService.errors.size > 0) {
      this.messageService.showErrors = true;
      console.log("Errors", this.messageService.errors);
    } else {
      if (!id) {
        this.setDefaultValues();
        this.adjustNotifyGroups();
        console.log("creating message", this.currentMessage);
        this.messageService.loading = true;
        if (this.messageType == MessageType.TOT) {
          this.currentMessage!.info = new MessageModel().info;
        }
        this.messageService.createMessage(this.currentMessage!)
          .subscribe({
            next: (res) => {
              let response = res as MessageModel;
              if (!UtilityService.validDate(response.validTo!)) {
                response.validTo = null;
              }
              if (!UtilityService.validDate(response.validFrom!)) {
                response.validFrom = null;
              }

              this.messageService.loading = false;
              this.setCurrentMessage(response);
              this.setOriginalMessage(this.createCopy(this.currentMessage!));
              this.messageService.updateList.emit(this.currentMessage!.id!);
              console.log(response);
            },
            error: () => this.messageService.loading = false
          });
      } else {
        this.messageService.loading = true;
        this.setDefaultValues();
        this.adjustNotifyGroups();
        this.currentMessage!.ver += 1;
        this.messageService.createMessage(this.currentMessage!)
          .subscribe({
            next: (res) => {
              let response = res as MessageModel;
              if (!UtilityService.validDate(response.validTo!)) {
                response.validTo = null;
              }
              if (!UtilityService.validDate(response.validFrom!)) {
                response.validFrom = null;
              }

              this.messageService.loading = false;
              this.setCurrentMessage(response);
              this.setOriginalMessage(this.createCopy(this.currentMessage!));
              this.messageService.updateList.emit(this.currentMessage!.id!);
            },
            error: () => this.messageService.loading = false
          });

        this.messageService
          .getMessageById(this.currentMessage!.id!)
          .subscribe({
            next: (d) => {
              let data = d as MessageModel[];
              data.map(message => {
                if (!UtilityService.validDate(message.validTo!)) {
                  message.validTo = null;
                }
                if (!UtilityService.validDate(message.validFrom!)) {
                  message.validFrom = null;
                }
              });
              if (data.find(x => x.ver > this.currentMessage!.ver)) {
                console.log("later version exists");
                this.laterMessageExists = true;
                this._message = data.sort((a, b) => this.sortByNumber(a, b));
                this.numberOfVersions = this._message.length;
              }
              else {
                this.laterMessageExists = false;
                this.currentMessage!.ver += 1;
                if (this.messageType == MessageType.TOT) {
                  this.currentMessage!.info = new MessageModel().info;
                }
              }
            },
            error: (error) => console.error(JSON.stringify(error))
          });
      }
    }
  }

  private adjustNotifyGroups() {
    if (this.messageType == MessageType.TI) {
      //Map group P-SV/KSV/OTS to groups P-SV/OTS, P-KSV before sending a message to BE
      NotifyGroupsService.mapAggregateGroupToIndividualGroups(this.currentMessage!.info.notifyGroups);
    }
  }

  closeMessage(id: string) {

    if (this.currentMessage && this.currentMessage.id == id) {

      this.originalMessage!.validTo = new Date();
      if (this.currentMessage.validFrom! > this.originalMessage!.validTo)
        this.originalMessage!.validFrom = this.originalMessage!.validTo;

      let ver = this.currentMessage.ver;
      this.messageService
        .updateMessage(this.originalMessage!)
        .subscribe({
          next: (res) => {
            let response = res as MessageModel;
            if (!UtilityService.validDate(response.validTo!)) {
              response.validTo = null;
            }
            if (!UtilityService.validDate(response.validFrom!)) {
              response.validFrom = null;
            }
            console.log("closed message response", response);
            let index = this.message.findIndex(x => x.ver == ver);
            this.message[index] = response;
            this.changeVersion(ver);
            this.messageService.updateList.emit(this.currentMessage!.id!);
          },
          error: (error) => console.error(JSON.stringify(error))
        });
    } else {
      console.log(`can't close message`);
    }
  }

  canUpdate() {
    return (
      !this.messageService.loading &&
      this.currentMessage!.id &&
      !UtilityService.equals(this.originalMessage, this.currentMessage)
    );
  }

  setDefaultValues() {
    if (!this.currentMessage?.createdAt)
      this.currentMessage!.createdAt = new Date();
    if (this.authService.getActiveAccount() != null) {
      var userToLog = this.authService.getUserNameAndEmail();
      if (!this.currentMessage?.createdBy)
        this.currentMessage!.createdBy = userToLog;
      this.currentMessage!.updatedBy = userToLog;
    }

    this.currentMessage!.updatedAt = new Date();

    if (!this.currentMessage!.validFrom)
      this.currentMessage!.validFrom = new Date();
    this.currentMessage!.validFrom.setSeconds(0);
  }

  useAsTemplate(id) {
    console.log("use as template", id);
    this.message = [
      new MessageModel(
        this.currentMessage!.body,
        null,
        null,
        this.currentMessage!.heading,
        null,
        Object.assign({}, this.currentMessage!.info, { notifyGroups: [] }) ,
        this.currentMessage!.sendMail,
        this.currentMessage!.type,
        null,
        null,
        null,
        null,
        1
      )
    ];
  }

  createCopy(message: MessageModel) {
    let newMessage = JSON.parse(JSON.stringify(message));

    // Convert newMessage validFrom/To from string to Date.
    this.ensureMessageFromToDatesAreDateType(newMessage);

    return newMessage;
  }

  ensureMessageFromToDatesAreDateType(message: MessageModel) {
    // Convert message validFrom/To from string to Date.
    if (typeof (message.validFrom) == typeof ("string"))
      message.validFrom = new Date(Date.parse(message.validFrom!.toString()));
    if (typeof (message.validTo) == typeof ("string"))
      message.validTo = new Date(Date.parse(message.validTo!.toString()));
  }

  pollForNewVersion() {
    if (!this.pollingActive) {
      this.pollingActive = true;
    }
    this.pollSubscription = interval(this.pollingInterval)
      .pipe(
        startWith(0),
        switchMap(() =>
          this.messageService.getMessageById(this.currentMessage!.id!)
        )
      )
      .subscribe({
        next: (d) => {
          let data = d as MessageModel[];
          data.map(message => {
            if (!UtilityService.validDate(message.validTo!)) {
              message.validTo = null;
            }
            if (!UtilityService.validDate(message.validFrom!)) {
              message.validFrom = null;
            }
          });
          if (data.length > this.numberOfVersions) {
            this.laterMessageExists = true;

            this.currentMessage = JSON.parse(JSON.stringify(this.currentMessage));

            // Convert this.currentMessage validFrom/To from string to Date.
            this.ensureMessageFromToDatesAreDateType(this.currentMessage!);

            this._message = data.sort((a, b) => this.sortByNumber(a, b));
            this.numberOfVersions = this._message.length;
          } else {
            this.laterMessageExists = false;
          }
        },
        error: (error) => console.error(JSON.stringify(error))
      });
  }

  setCurrentMessage(message: MessageModel) {
    this.currentMessage = message;
    this.messageService.currentMessage = message;
  }
  setOriginalMessage(message: MessageModel) {
    this.originalMessage = message;
    this.messageService.originalMessage = message;
  }

  isReadOnly() {
    return !this.authService.hasReadWriteGroups();
  }
  getMessageContainer() {
    if (this.activatedRoute.snapshot.paramMap.has("id")) {
      return "new-window-message-container";
    }
    return "message-container";
  }
  getNewWindowClass() {
    if (this.activatedRoute.snapshot.paramMap.has("id")) {
      return true;
    }
    return false;
  }
}
