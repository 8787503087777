import { Component, OnInit, signal, ViewChild, ViewChildren } from "@angular/core";
import { CategoryModel } from "../../../models/CategoryModel";
import { Store } from "@ngrx/store";
import { selectCategoriesData } from "../../../store/categories/categories.selector";
import { Observable } from "rxjs";
import { fetchCategoriesData } from "../../../store/categories/categories.action";
import { PhonebookService } from "../../../services/phonebook.service";
import { AuthService } from "../../../services/auth.service";
import { userReadWriteGroups } from "../../../../assets/user-groups";
import { ContactsTableComponent } from "./contacts-table/contacts-table.component";
import { MatAccordion } from "@angular/material/expansion";
import { DialogService } from "../../../services/dialog.service";

@Component({
  selector: "contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.scss"],
})
export class ContactsComponent implements OnInit {
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @ViewChildren(ContactsTableComponent) contactTable!: ContactsTableComponent[];
  categoriesData$: Observable<CategoryModel[]>;
  categories: CategoryModel[] = [];
  readonly allExpandedState = signal(false);
  readonly panelOpenState = new Set();

  constructor(private authService: AuthService, private store: Store, private dialogService: DialogService) {
    this.categoriesData$ = this.store.select(selectCategoriesData);
  }

  async ngOnInit(): Promise<void> {
    await this.authService.hasGroupsAsync(userReadWriteGroups.DLI);
    this.authService.checkContactGroupAccess();

    this.store.dispatch(fetchCategoriesData());

    this.categoriesData$.subscribe(categoriesData => {
      this.categories = [...categoriesData].sort((a, b) => {
        return PhonebookService.compare(a.categoryPriorityOrder!, b.categoryPriorityOrder!, true);
      });
    });
  }

  /**
   * Used to show confirmation when navigating via nav buttons and user has changes/dirty table
   * https://developer.mozilla.org/en-US/docs/Web/API/BeforeUnloadEvent
   */
  canDeactivate() {
    const containsUnsavedChanges = this.contactTable.some(table => table.hasUnsavedChanges());
    if (!containsUnsavedChanges) {
      return true;
    }

    return this.dialogService.unsavedNavigationConfirmation();
  }

  /**
   * Needed to track an id (instead of object identifier) in order to not close
   * according/re-render whole table when we update sorting.
   */
  trackById(_: number, category: CategoryModel) {
    return category.categoryId;
  }

  toggleAllExpanded() {
    this.allExpandedState.update(prev => !prev);
  }

  hasChildChanges(categoryId: string) {
    const categoryTable = this.contactTable.find(table => table.categoryId === categoryId);
    return Boolean(categoryTable?.hasUnsavedChanges())
  }
}
