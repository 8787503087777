import {createAction, props} from "@ngrx/store";
import {CategoryModel} from "../../models/CategoryModel";
import {CategoryApiModel} from "../../models/api-category.model";
import {ContactApiModel} from "../../models/api-contact.model";
import {SaveMethod} from "../../types/contacts-types";

export const fetchCategoriesData = createAction(
  '[Contacts] Fetch Categories Data'
)

export const updateCategoriesData = createAction(
  '[Contacts] Update Categories Data',
  props<{ categoriesData: CategoryModel[] }>()
)

export const updateCategoryData = createAction(
  '[Category] Update existing category (on server)',
  props<{ category: CategoryApiModel, saveMethod: SaveMethod }>()
)

export const createCategoryData = createAction(
  '[Category] Create a new category (on server)',
  props<{ id: string, category: Omit<CategoryApiModel, "id" | "categoryPriorityOrder"> }>()
)

export const deleteCategoryData = createAction(
  '[Category] Delete a category (on server)',
  props<{ categoryId: string }>()
)

export const updateContactData = createAction(
  '[Contact] Update existing category (on server)',
  props<{ contact: ContactApiModel, saveMethod: SaveMethod }>()
)

export const createContactData = createAction(
  '[Contact] Create a new contact (on server)',
  props<{ id: string, contact: Omit<ContactApiModel, "id" | "priorityOrder"> }>()
)

export const deleteContactData = createAction(
  '[Contact] Delete a contact (on server)',
  props<{ contactId: string }>()
)

export const addEditingRowsData = createAction(
  '[Contact/Category] Update editing rows with a new entry',
  props<{ rowId: string }>()
)

export const removeEditingRowsData = createAction(
  '[Contact/Category] Remove entry from editing rows',
  props<{ rowId: string }>()
)

export const resetEditingRowsData = createAction(
  '[Contact/Category] Reset editing rows',
)



