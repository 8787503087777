<div class="header-content" [ngClass]="{ 'new-message': !message?.id }">
  <div class="header-row-container">
    <div class="header-button-container">
      <button class="clear-button"
              *ngIf="!isReadOnly() && message?.id"
              [disabled]="!canClose()"
              (click)="onClose()">
        <mat-icon class="icon-with-space">visibility_off</mat-icon> Släck
      </button>
      <button class="clear-button"
              *ngIf="!message?.id && !isReadOnly() && showButton"
              (click)="openNewWindow()">
        <mat-icon class="icon-with-space" matTooltip="Eget fönster">open_in_new</mat-icon>
      </button>
      
      <button *ngIf="!message?.id && !isReadOnly()"
              class="confirm-button confirm-form"
              (click)="onSave()">
        <mat-icon class="icon-with-space">send</mat-icon> Skicka
      </button>
      <button *ngIf="message?.id && !isReadOnly()"
              [disabled]="!canUpdate()"
              class="confirm-button confirm-form"
              (click)="onSave()">
        <mat-icon class="icon-with-space">loop</mat-icon> Uppdatera
      </button>
    </div>
    <div class="header-row-title">
      <h4 class="title" *ngIf="message?.id">{{ message?.heading }}</h4>
      <h4 class="title" style="margin-left: 10px; margin-right: 10px;" *ngIf="!message?.id">Nytt meddelande</h4>
    </div>
  </div>

  <hr />
  <div *ngIf="message?.id" class="header-button-container">
    <button class="clear-button"
            *ngIf="!isReadOnly() && showButton"
            (click)="openNewWindow()">
      <mat-icon class="icon-with-space">open_in_new</mat-icon> Eget fönster
    </button>
    <div class="row-content">
      <button class="transparent-button chevron"
              [disabled]="message!.ver == 1"
              (click)="onVersionChange(false)">
        <mat-icon class="icon-adjustment">chevron_left</mat-icon>
      </button>
      <span class="header-text-bold">VERSION {{ message!.ver }}</span>
      <button class="transparent-button chevron"
              [disabled]="message!.ver >= numberOfVersions"
              (click)="onVersionChange(true)">
        <mat-icon class="icon-adjustment">chevron_right</mat-icon>
      </button>
      <span class="header-text">Senast ändrad: </span>
      <span class="header-text-bold">
        {{
        message?.updatedAt! | date: "dd MMM yyyy HH:mm"
        }}
      </span>
    </div>

    <button class="clear-button"
            *ngIf="!isReadOnly()"
            (click)="onUseAsTemplate()">
      <mat-icon class="icon-with-space">filter_none</mat-icon> Använd som mall
    </button>
  </div>
  <span class="newer-version-warning" *ngIf="laterMessageExists"
    >Det finns en nyare version av meddelandet!</span
  >
  <hr *ngIf="message?.id" />
</div>
