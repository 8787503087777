<mat-toolbar color="primary" class="dark-nav">
  <div class="navbar-brand">
    <div class="navbar-item">
      <button
        mat-button
        class="transparent-button-light menu-button"
        (click)="isExpanded = !isExpanded"
      >
        <div class="container" [ngClass]="{ change: isExpanded }">
          <div class="bar1"></div>
          <div class="bar2"></div>
          <div class="bar3"></div>
        </div>
      </button>
      <a href="#"
      ><img
        class="navbar-logo"
        src="./assets/images/amigo_logo.svg"
        alt="Amigo Logotyp"/></a>

      <app-drop-down
        *ngIf="isExpanded"
        class="menu-dropdown"
        (close)="changeHeader($event)"
      ></app-drop-down>
    </div>
  </div>
  <div class="title">{{ currentPageName }}</div>
  <!-- <div class="title">
    <div
      *ngFor="let menuItem of menuItems"
      class="menu-item"
      [routerLinkActive]="['active']"
    >
      <a
        [routerLink]="menuItem.path"
        class="menu-link"
        (click)="onClick(menuItem.path)"
      >
        {{ menuItem.displayName }}
      </a>
    </div>
  </div> -->
  <div *ngIf="loggedIn()" class="right-side-menu-item">
    <div class="user-menu-item">
      <mat-icon class="menu-icon" aria-hidden="false" aria-label="User"
      >person</mat-icon
      >
      {{ user() }}
    </div>

    <button
      mat-icon-button
      aria-label="Sign out"
      class="transparent-button"
      (click)="logout()"
    >
      <mat-icon class="menu-icon">exit_to_app</mat-icon>
    </button>
  </div>
</mat-toolbar>
