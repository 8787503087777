<div>
  <a mat-button routerLinkActive="active" [routerLink]="'/kontakter'">Kontakter</a>
  <a mat-button routerLinkActive="active" [routerLink]="'/kategorier'">Kategorier</a>
</div>

<div class="container">
  <div>
    <button (click)="toggleAllExpanded()">Expandera/minimera kategorier</button>
    <mat-accordion [multi]="true">
      <mat-expansion-panel *ngFor="let category of categories; trackBy: trackById;" [expanded]="allExpandState">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ category.categoryName }}</mat-panel-title>
          <mat-panel-description>{{ category.contacts.length }} kontakter</mat-panel-description>
        </mat-expansion-panel-header>
        <contact-sortable-table [categoryId]="category.categoryId"/>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
