<div [ngClass] ="getMessageContainer()" *ngIf="message">
  
  <app-header
    [message]="originalMessage"
    [numberOfVersions]="numberOfVersions"
    [laterMessageExists]="laterMessageExists"
    (changeVersion)="changeVersion($event)"
    (saveChanges)="saveChanges($event)"
    (closeMessage)="closeMessage($event)"
  >
</app-header>

  <div class="message-body" [ngClass] ="{'new-message': !currentMessage?.id, 'new-window' : getNewWindowClass()}">
   
    <div style="display: flow-root;">
      <div
        class="checkbox-group"
        *ngIf="isNewMessage"
      >
        <span style="margin-right:10px;"> Skicka till </span>
        <button
          class="transparent-button-light-big checkbox"
          (click)="onChangeMessageType(true)"
        >
          <mat-icon class="button-checkbox" *ngIf="showApp"
          >radio_button_checked</mat-icon
          >
          <mat-icon class="button-checkbox" *ngIf="!showApp"
          >radio_button_unchecked</mat-icon
          >
          <span class="button-checkbox">Appen</span>
        </button>
        <button
          class="transparent-button-light-big checkbox"
          (click)="onChangeMessageType(false)"
        >
          <mat-icon class="button-checkbox" *ngIf="!showApp"
          >radio_button_checked</mat-icon
          >
          <mat-icon class="button-checkbox" *ngIf="showApp"
          >radio_button_unchecked</mat-icon
          >
          <span class="button-checkbox">Tåg i Tid</span>
        </button>
      </div>
    </div>

    <span class="new-message-info" *ngIf="!isNewMessage">
        Skickas till: <b> {{ showApp ? "Appen" : "Tåg i Tid" }} </b>
    </span>

    <app-app-message-form
      *ngIf="messageType == 'TI'"
      [message]="currentMessage!"
      [numberOfVersions]="numberOfVersions"
    />
    <app-web-message-form
      *ngIf="messageType == 'TOT'"
      [message]="currentMessage!"
      [numberOfVersions]="numberOfVersions"
    />
  </div>
</div>
<div class="text-container" *ngIf="!message">
  <div class="centered-text">Inget meddelande valt</div>
</div>
